import { Routes } from '@angular/router';
import { AuthGuard } from '@mymahi/authentication';

export const routes: Routes = [
    {
        path: 'offline',
        loadChildren: () => import('./offline/offline.routes')
    },
    {
        path: '',
        loadChildren: () => import('./navigation/navigation.routes'),
        canLoad: [AuthGuard]
    }
];
