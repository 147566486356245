import { MediaMatcher } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, from, fromEvent, of } from 'rxjs';
import { filter, first, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

type InstalledRelatedApps = { id: string; platform: string; url: string }[];

@Injectable({ providedIn: 'root' })
export class InstallService implements OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();
    private beforeInstallPromptEvent?: Event;

    private installed = false;
    private installedSubject: Subject<boolean> = new BehaviorSubject<boolean>(this.installed);
    public get isInstalled(): boolean {
        return this.installed;
    }
    public readonly isInstalled$ = this.installedSubject.asObservable().pipe(takeUntil(this.ngUnsubscribe));

    private installable = false;
    private installableSubject: Subject<boolean> = new BehaviorSubject<boolean>(this.installable);
    public get canInstall(): boolean {
        return this.installable;
    }
    public readonly canInstall$ = this.installableSubject.asObservable().pipe(takeUntil(this.ngUnsubscribe));

    public get useIosSheet(): boolean {
        return this.installable && this.beforeInstallPromptEvent == null;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private mediaMatcher: MediaMatcher,
        private platform: Platform
    ) {
        // Do setup and checks for whether the app is installed
        if (this.mediaMatcher.matchMedia('(display-mode: standalone)').matches) {
            this.installed = true;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if ((document.defaultView.navigator as any).standalone) {
            this.installed = true;
        }

        this.installedSubject.next(this.installed);

        fromEvent(this.mediaMatcher.matchMedia('(display-mode: standalone)'), 'change')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: MediaQueryListEvent) => {
                if (event.matches) {
                    this.installed = true;
                    this.installedSubject.next(this.installed);
                }
            });

        if (!this.platform.SAFARI) {
            fromEvent(this.document.defaultView, 'beforeinstallprompt')
                .pipe(
                    tap((e) => e.preventDefault()),
                    mergeMap((e: Event) =>
                        // Check for installed apps if method available
                        // If app already installed then suppress the beforeinstallprompt
                        'getInstalledRelatedApps' in this.document.defaultView.navigator
                            ? from(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (this.document.defaultView.navigator as any).getInstalledRelatedApps() as Promise<InstalledRelatedApps>
                              ).pipe(map((results) => (results.length === 0 ? e : null)))
                            : of(e)
                    ),
                    filter((e) => e != null),
                    first(),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe((e: Event) => {
                    this.beforeInstallPromptEvent = e;
                    this.installable = true;
                    this.installableSubject.next(this.installable);
                });
        } else if (this.platform.IOS) {
            setTimeout(() => {
                if (!this.installed) {
                    this.installable = true;
                    this.installableSubject.next(this.installable);
                }
            }, 5000);
        }
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public initiateInstall() {
        if (!this.installable || this.beforeInstallPromptEvent == null) {
            throw new Error('tried to initiate install when not installable');
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = this.beforeInstallPromptEvent as any;
        return from(e.prompt().then(() => e.userChoice) as Promise<{ outcome: 'accepted' | 'dismissed'; platform: string }>).pipe(
            map((choice) => {
                if (choice.outcome === 'accepted') {
                    this.installable = false;
                    this.installableSubject.next(this.installable);
                    this.beforeInstallPromptEvent = null;
                }
                return choice;
            }),
            takeUntil(this.ngUnsubscribe)
        );
    }
}
