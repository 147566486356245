import { Lazy } from '@mymahi/utility';
import { MentorSharedResourceType } from '../queries';

export const MentorSharedResourceTypeDisplayStrings = new Lazy(() => {
    return {
        [MentorSharedResourceType.GETTING_ORGANISED]: $localize`Getting Organised`,
        [MentorSharedResourceType.GOALS]: $localize`Goals`,
        [MentorSharedResourceType.HEALTH_AND_WELLBEING]: $localize`Health and Wellbeing`,
        [MentorSharedResourceType.LEARNING]: $localize`Learning`,
        [MentorSharedResourceType.MONEY]: $localize`Money`,
        [MentorSharedResourceType.REFLECTIONS]: $localize`Reflections`,
        [MentorSharedResourceType.WORK_READY]: $localize`Work Ready`,
        [MentorSharedResourceType.VALUES_AND_BELIEFS]: $localize`Values and Beliefs`
    };
});
