import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Subject, interval } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UpdateService implements OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();

    private updateStatus: 'NO_UPDATE' | 'DOWNLOADING' | 'READY' = 'NO_UPDATE';
    private updateStatusSubject = new BehaviorSubject<'NO_UPDATE' | 'DOWNLOADING' | 'READY'>(this.updateStatus);
    public get currentUpdateStatus(): 'NO_UPDATE' | 'DOWNLOADING' | 'READY' {
        return this.updateStatus;
    }
    public readonly currentUpdateStatus$ = this.updateStatusSubject.asObservable().pipe(takeUntil(this.ngUnsubscribe));

    constructor(private swUpdate: SwUpdate) {
        this.swUpdate.versionUpdates.pipe(takeUntil(this.ngUnsubscribe)).subscribe((versionEvent) => {
            if (versionEvent.type === 'NO_NEW_VERSION_DETECTED') {
                // this generates out too many log lines
                // console.log(`no new version detected, current version: ${versionEvent.version.hash}`);
                this.updateStatus = 'NO_UPDATE';
            } else if (versionEvent.type === 'VERSION_DETECTED') {
                console.log(`new version detected: ${versionEvent.version.hash}`);
                this.updateStatus = 'DOWNLOADING';
            } else if (versionEvent.type === 'VERSION_READY') {
                console.log(`new version ready: ${versionEvent.latestVersion.hash}, current version: ${versionEvent.currentVersion.hash}`);
                this.updateStatus = 'READY';
            } else {
                this.swUpdate.checkForUpdate().catch((err) => console.error(err));
            }
            this.updateStatusSubject.next(this.updateStatus);
        });

        interval(60_000)
            .pipe(
                startWith(0),
                takeUntil(this.ngUnsubscribe),
                filter(() => this.swUpdate.isEnabled)
            )
            .subscribe(() => this.swUpdate.checkForUpdate().catch((err) => console.error(err)));
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public activateUpdate(): Promise<void> {
        return this.swUpdate.activateUpdate().then(
            () => {
                return;
            },
            (err) => {
                console.log(err);
            }
        );
    }
}
