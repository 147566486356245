import { Lazy } from '@mymahi/utility';

export enum RiasecInterestType {
    REALISTIC = 'R',
    INVESTIGATIVE = 'I',
    ARTISTIC = 'A',
    SOCIAL = 'S',
    ENTERPRISING = 'E',
    CONVENTIONAL = 'C'
}

export interface RiasecInterestDetail {
    title: string;
    description: string;
    icon: string;
    initial: string;
    urlSegment: string;
    color: string;
}

export const RiasecInterestDetails = new Lazy((): Record<RiasecInterestType, RiasecInterestDetail> => {
    return {
        [RiasecInterestType.REALISTIC]: {
            title: $localize`Realistic`,
            description: $localize`These people are often good at mechanical or athletic jobs.`,
            icon: '/assets/svg/career/realistic.svg',
            initial: 'R',
            urlSegment: 'Realistic',
            color: '#6cc624'
        },
        [RiasecInterestType.INVESTIGATIVE]: {
            title: $localize`Investigative`,
            description: $localize`These people like to watch, learn, analyze and solve problems.`,
            icon: '/assets/svg/career/investigative.svg',
            initial: 'I',
            urlSegment: 'Investigative',
            color: '#19affa'
        },
        [RiasecInterestType.ARTISTIC]: {
            title: $localize`Artistic`,
            description: $localize`These people like to work in unstructured situations where they can use their creativity.`,
            icon: '/assets/svg/career/artistic.svg',
            initial: 'A',
            urlSegment: 'Artistic',
            color: '#ee7465'
        },
        [RiasecInterestType.SOCIAL]: {
            title: $localize`Social`,
            description: $localize`These people like to work with other people, rather than things.`,
            icon: '/assets/svg/career/social.svg',
            initial: 'S',
            urlSegment: 'Social',
            color: '#fdd017'
        },
        [RiasecInterestType.ENTERPRISING]: {
            title: $localize`Enterprising`,
            description: $localize`These people like to work with others and enjoy persuading and and performing.`,
            icon: '/assets/svg/career/enterprising.svg',
            initial: 'E',
            urlSegment: 'Enterprising',
            color: '#8566f8'
        },
        [RiasecInterestType.CONVENTIONAL]: {
            title: $localize`Conventional`,
            description: $localize`These people are very detail oriented, organised and like to work with data.`,
            icon: '/assets/svg/career/conventional.svg',
            initial: 'C',
            urlSegment: 'Conventional',
            color: '#ff9000'
        }
    };
});

export interface RiasecTestQuestion {
    statement: string;
    type: RiasecInterestType;
}

export const RiasecTestQuestions = new Lazy((): RiasecTestQuestion[] => {
    return [
        {
            statement: $localize`I like to work on cars`,
            type: RiasecInterestType.REALISTIC
        },
        {
            statement: $localize`I like to do puzzles`,
            type: RiasecInterestType.INVESTIGATIVE
        },
        {
            statement: $localize`I am good at working independently`,
            type: RiasecInterestType.ARTISTIC
        },
        {
            statement: $localize`I like to work in teams`,
            type: RiasecInterestType.SOCIAL
        },
        {
            statement: $localize`I am an ambitious person, I set goals for myself`,
            type: RiasecInterestType.ENTERPRISING
        },
        {
            statement: $localize`I like to organise things, files, and work areas`,
            type: RiasecInterestType.CONVENTIONAL
        },
        {
            statement: $localize`I like to build things`,
            type: RiasecInterestType.REALISTIC
        },
        {
            statement: $localize`I like to read about art and music`,
            type: RiasecInterestType.ARTISTIC
        },
        {
            statement: $localize`I like to have clear instructions to follow`,
            type: RiasecInterestType.CONVENTIONAL
        },
        {
            statement: $localize`I like to try to influence or persuade people`,
            type: RiasecInterestType.ENTERPRISING
        },
        {
            statement: $localize`I like to do experiments`,
            type: RiasecInterestType.INVESTIGATIVE
        },
        {
            statement: $localize`I like to teach or train people`,
            type: RiasecInterestType.SOCIAL
        },
        {
            statement: $localize`I like trying to help people solve their problems`,
            type: RiasecInterestType.SOCIAL
        },
        {
            statement: $localize`I like to take care of animals`,
            type: RiasecInterestType.REALISTIC
        },
        {
            statement: $localize`I wouldn't mind working 8 hours per day in an office`,
            type: RiasecInterestType.CONVENTIONAL
        },
        {
            statement: $localize`I like selling things`,
            type: RiasecInterestType.ENTERPRISING
        },
        {
            statement: $localize`I enjoy creative writing`,
            type: RiasecInterestType.ARTISTIC
        },
        {
            statement: $localize`I enjoy science`,
            type: RiasecInterestType.INVESTIGATIVE
        },
        {
            statement: $localize`I am quick to take on new responsibilities`,
            type: RiasecInterestType.ENTERPRISING
        },
        {
            statement: $localize`I am interested in healing people`,
            type: RiasecInterestType.SOCIAL
        },
        {
            statement: $localize`I enjoy trying to figure out how things work`,
            type: RiasecInterestType.INVESTIGATIVE
        },
        {
            statement: $localize`I like putting things together or assembling things`,
            type: RiasecInterestType.REALISTIC
        },
        {
            statement: $localize`I am a creative person`,
            type: RiasecInterestType.ARTISTIC
        },
        {
            statement: $localize`I pay attention to details`,
            type: RiasecInterestType.CONVENTIONAL
        },
        {
            statement: $localize`I like to do filing or typing`,
            type: RiasecInterestType.CONVENTIONAL
        },
        {
            statement: $localize`I like to analyze things, such as problems, situations, or data`,
            type: RiasecInterestType.INVESTIGATIVE
        },
        {
            statement: $localize`I like to play instruments or sing`,
            type: RiasecInterestType.ARTISTIC
        },
        {
            statement: $localize`I enjoy learning about other cultures`,
            type: RiasecInterestType.SOCIAL
        },
        {
            statement: $localize`I would like to start my own business`,
            type: RiasecInterestType.ENTERPRISING
        },
        {
            statement: $localize`I like to cook`,
            type: RiasecInterestType.REALISTIC
        },
        {
            statement: $localize`I like acting in plays`,
            type: RiasecInterestType.ARTISTIC
        },
        {
            statement: $localize`I am a practical person`,
            type: RiasecInterestType.REALISTIC
        },
        {
            statement: $localize`I like working with numbers or charts`,
            type: RiasecInterestType.INVESTIGATIVE
        },
        {
            statement: $localize`I like get into discussions about issues`,
            type: RiasecInterestType.SOCIAL
        },
        {
            statement: $localize`I am good at keeping records of my work`,
            type: RiasecInterestType.CONVENTIONAL
        },
        {
            statement: $localize`I like to lead`,
            type: RiasecInterestType.ENTERPRISING
        },
        {
            statement: $localize`I like working outdoors`,
            type: RiasecInterestType.REALISTIC
        },
        {
            statement: $localize`I would like working in an office`,
            type: RiasecInterestType.CONVENTIONAL
        },
        {
            statement: $localize`I am good at math`,
            type: RiasecInterestType.INVESTIGATIVE
        },
        {
            statement: $localize`I like helping people`,
            type: RiasecInterestType.SOCIAL
        },
        {
            statement: $localize`I like to draw`,
            type: RiasecInterestType.ARTISTIC
        },
        {
            statement: $localize`I like to give speeches`,
            type: RiasecInterestType.ENTERPRISING
        }
    ];
});
