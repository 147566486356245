export enum Locale {
    en = 'en',
    hi = 'hi',
    ja = 'ja',
    ko = 'ko',
    ms = 'ms',
    vi = 'vi',
    zh = 'zh'
}

export const LocaleDisplayString = {
    [Locale.en]: $localize`English`,
    [Locale.hi]: $localize`Hindi`,
    [Locale.ja]: $localize`Japanese`,
    [Locale.ko]: $localize`Korean`,
    [Locale.ms]: $localize`Malay`,
    [Locale.vi]: $localize`Vietnamese`,
    [Locale.zh]: $localize`Chinese`
};

export const Locales = [Locale.zh, Locale.en, Locale.hi, Locale.ja, Locale.ko, Locale.ms, Locale.vi];

export const Languages = [
    { name: 'English', iso: 'en' },
    { name: 'Afaraf', iso: 'aa' },
    { name: 'аҧсуа бызшәа, аҧсшәа', iso: 'ab' },
    { name: 'avesta', iso: 'ae' },
    { name: 'Afrikaans', iso: 'af' },
    { name: 'Akan', iso: 'ak' },
    { name: 'አማርኛ', iso: 'am' },
    { name: 'aragonés', iso: 'an' },
    { name: 'العربية', iso: 'ar' },
    { name: 'অসমীয়া', iso: 'as' },
    { name: 'авар мацӀ, магӀарул мацӀ', iso: 'av' },
    { name: 'aymar aru', iso: 'ay' },
    { name: 'azərbaycan dili', iso: 'az' },
    { name: 'башҡорт теле', iso: 'ba' },
    { name: 'беларуская мова', iso: 'be' },
    { name: 'български език', iso: 'bg' },
    { name: 'भोजपुरी', iso: 'bh' },
    { name: 'Bislama', iso: 'bi' },
    { name: 'bamanankan', iso: 'bm' },
    { name: 'বাংলা', iso: 'bn' },
    { name: 'བོད་ཡིག', iso: 'bo' },
    { name: 'brezhoneg', iso: 'br' },
    { name: 'bosanski jezik', iso: 'bs' },
    { name: 'català, valencià', iso: 'ca' },
    { name: 'нохчийн мотт', iso: 'ce' },
    { name: 'Chamoru', iso: 'ch' },
    { name: 'corsu, lingua corsa', iso: 'co' },
    { name: 'ᓀᐦᐃᔭᐍᐏᐣ', iso: 'cr' },
    { name: 'čeština, český jazyk', iso: 'cs' },
    { name: 'ѩзыкъ словѣньскъ', iso: 'cu' },
    { name: 'чӑваш чӗлхи', iso: 'cv' },
    { name: 'Cymraeg', iso: 'cy' },
    { name: 'dansk', iso: 'da' },
    { name: 'Deutsch', iso: 'de' },
    { name: 'ދިވެހި', iso: 'dv' },
    { name: 'རྫོང་ཁ', iso: 'dz' },
    { name: 'Eʋegbe', iso: 'ee' },
    { name: 'ελληνικά', iso: 'el' },
    { name: 'Esperanto', iso: 'eo' },
    { name: 'Español', iso: 'es' },
    { name: 'eesti, eesti keel', iso: 'et' },
    { name: 'euskara, euskera', iso: 'eu' },
    { name: 'فارسی', iso: 'fa' },
    { name: 'Fulfulde, Pulaar, Pular', iso: 'ff' },
    { name: 'suomi, suomen kieli', iso: 'fi' },
    { name: 'vosa Vakaviti', iso: 'fj' },
    { name: 'føroyskt', iso: 'fo' },
    { name: 'français, langue française', iso: 'fr' },
    { name: 'Frysk', iso: 'fy' },
    { name: 'Gaeilge', iso: 'ga' },
    { name: 'Gàidhlig', iso: 'gd' },
    { name: 'Galego', iso: 'gl' },
    { name: "Avañe'ẽ", iso: 'gn' },
    { name: 'ગુજરાતી', iso: 'gu' },
    { name: 'Gaelg, Gailck', iso: 'gv' },
    { name: '(Hausa) هَوُسَ', iso: 'ha' },
    { name: 'עברית', iso: 'he' },
    { name: 'हिन्दी, हिंदी', iso: 'hi' },
    { name: 'Hiri Motu', iso: 'ho' },
    { name: 'hrvatski jezik', iso: 'hr' },
    { name: 'Kreyòl ayisyen', iso: 'ht' },
    { name: 'magyar', iso: 'hu' },
    { name: 'Հայերեն', iso: 'hy' },
    { name: 'Otjiherero', iso: 'hz' },
    { name: 'Interlingua', iso: 'ia' },
    { name: 'Bahasa Indonesia', iso: 'id' },
    { name: 'Originally called Occidental; then Interlingue after WWII', iso: 'ie' },
    { name: 'Asụsụ Igbo', iso: 'ig' },
    { name: 'ꆈꌠ꒿ Nuosuhxop', iso: 'ii' },
    { name: 'Iñupiaq, Iñupiatun', iso: 'ik' },
    { name: 'Ido', iso: 'io' },
    { name: 'Íslenska', iso: 'is' },
    { name: 'Italiano', iso: 'it' },
    { name: 'ᐃᓄᒃᑎᑐᑦ', iso: 'iu' },
    { name: '日本語 (にほんご)', iso: 'ja' },
    { name: 'ꦧꦱꦗꦮ, Basa Jawa', iso: 'jv' },
    { name: 'ქართული', iso: 'ka' },
    { name: 'Kikongo', iso: 'kg' },
    { name: 'Gĩkũyũ', iso: 'ki' },
    { name: 'Kuanyama', iso: 'kj' },
    { name: 'қазақ тілі', iso: 'kk' },
    { name: 'kalaallisut, kalaallit oqaasii', iso: 'kl' },
    { name: 'ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ', iso: 'km' },
    { name: 'ಕನ್ನಡ', iso: 'kn' },
    { name: '한국어', iso: 'ko' },
    { name: 'Kanuri', iso: 'kr' },
    { name: 'कश्मीरी, كشميري‎', iso: 'ks' },
    { name: 'Kurdî, کوردی‎', iso: 'ku' },
    { name: 'коми кыв', iso: 'kv' },
    { name: 'Kernewek', iso: 'kw' },
    { name: 'Кыргызча, Кыргыз тили', iso: 'ky' },
    { name: 'latine, lingua latina', iso: 'la' },
    { name: 'Lëtzebuergesch', iso: 'lb' },
    { name: 'Luganda', iso: 'lg' },
    { name: 'Limburgs', iso: 'li' },
    { name: 'Lingála', iso: 'ln' },
    { name: 'ພາສາລາວ', iso: 'lo' },
    { name: 'lietuvių kalba', iso: 'lt' },
    { name: 'Kiluba', iso: 'lu' },
    { name: 'latviešu valoda', iso: 'lv' },
    { name: 'fiteny malagasy', iso: 'mg' },
    { name: 'Kajin M̧ajeļ', iso: 'mh' },
    { name: 'te reo Māori', iso: 'mi' },
    { name: 'македонски јазик', iso: 'mk' },
    { name: 'മലയാളം', iso: 'ml' },
    { name: 'Монгол хэл', iso: 'mn' },
    { name: 'मराठी', iso: 'mr' },
    { name: 'Bahasa Melayu, بهاس ملايو‎', iso: 'ms' },
    { name: 'Malti', iso: 'mt' },
    { name: 'ဗမာစာ', iso: 'my' },
    { name: 'Dorerin Naoero', iso: 'na' },
    { name: 'Norsk Bokmål', iso: 'nb' },
    { name: 'isiNdebele', iso: 'nd' },
    { name: 'नेपाली', iso: 'ne' },
    { name: 'Owambo', iso: 'ng' },
    { name: 'Nederlands, Vlaams', iso: 'nl' },
    { name: 'Norsk Nynorsk', iso: 'nn' },
    { name: 'Norsk', iso: 'no' },
    { name: 'isiNdebele', iso: 'nr' },
    { name: 'Diné bizaad', iso: 'nv' },
    { name: 'chiCheŵa, chinyanja', iso: 'ny' },
    { name: "occitan, lenga d'òc", iso: 'oc' },
    { name: 'ᐊᓂᔑᓈᐯᒧᐎᓐ', iso: 'oj' },
    { name: 'Afaan Oromoo', iso: 'om' },
    { name: 'ଓଡ଼ିଆ', iso: 'or' },
    { name: 'ирон æвзаг', iso: 'os' },
    { name: 'ਪੰਜਾਬੀ', iso: 'pa' },
    { name: 'पाऴि', iso: 'pi' },
    { name: 'język polski, polszczyzna', iso: 'pl' },
    { name: 'پښتو', iso: 'ps' },
    { name: 'Português', iso: 'pt' },
    { name: 'Runa Simi, Kichwa', iso: 'qu' },
    { name: 'Rumantsch Grischun', iso: 'rm' },
    { name: 'Ikirundi', iso: 'rn' },
    { name: 'Română', iso: 'ro' },
    { name: 'русский', iso: 'ru' },
    { name: 'Ikinyarwanda', iso: 'rw' },
    { name: 'संस्कृतम्', iso: 'sa' },
    { name: 'sardu', iso: 'sc' },
    { name: 'सिन्धी, سنڌي، سندھی‎', iso: 'sd' },
    { name: 'Davvisámegiella', iso: 'se' },
    { name: 'yângâ tî sängö', iso: 'sg' },
    { name: 'සිංහල', iso: 'si' },
    { name: 'Slovenčina, Slovenský Jazyk', iso: 'sk' },
    { name: 'Slovenski Jezik, Slovenščina', iso: 'sl' },
    { name: "gagana fa'a Samoa", iso: 'sm' },
    { name: 'chiShona', iso: 'sn' },
    { name: 'Soomaaliga, af Soomaali', iso: 'so' },
    { name: 'Shqip', iso: 'sq' },
    { name: 'српски језик', iso: 'sr' },
    { name: 'SiSwati', iso: 'ss' },
    { name: 'Sesotho', iso: 'st' },
    { name: 'Basa Sunda', iso: 'su' },
    { name: 'Svenska', iso: 'sv' },
    { name: 'Kiswahili', iso: 'sw' },
    { name: 'தமிழ்', iso: 'ta' },
    { name: 'తెలుగు', iso: 'te' },
    { name: 'тоҷикӣ, toçikī, تاجیکی‎', iso: 'tg' },
    { name: 'ไทย', iso: 'th' },
    { name: 'ትግርኛ', iso: 'ti' },
    { name: 'Türkmen, Түркмен', iso: 'tk' },
    { name: 'Wikang Tagalog', iso: 'tl' },
    { name: 'Setswana', iso: 'tn' },
    { name: 'Faka Tonga', iso: 'to' },
    { name: 'Türkçe', iso: 'tr' },
    { name: 'Xitsonga', iso: 'ts' },
    { name: 'татар теле, tatar tele', iso: 'tt' },
    { name: 'Twi', iso: 'tw' },
    { name: 'Reo Tahiti', iso: 'ty' },
    { name: 'ئۇيغۇرچە‎, Uyghurche', iso: 'ug' },
    { name: 'Українська', iso: 'uk' },
    { name: 'اردو', iso: 'ur' },
    { name: 'Oʻzbek, Ўзбек, أۇزبېك‎', iso: 'uz' },
    { name: 'Tshivenḓa', iso: 've' },
    { name: 'Tiếng Việt', iso: 'vi' },
    { name: 'Volapük', iso: 'vo' },
    { name: 'Walon', iso: 'wa' },
    { name: 'Wollof', iso: 'wo' },
    { name: 'isiXhosa', iso: 'xh' },
    { name: 'ייִדיש', iso: 'yi' },
    { name: 'Yorùbá', iso: 'yo' },
    { name: 'Saɯ cueŋƅ, Saw cuengh', iso: 'za' },
    { name: '中文 (Zhōngwén), 汉语, 漢語', iso: 'zh' },
    { name: 'isiZulu', iso: 'zu' }
];
