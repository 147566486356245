/* eslint-disable @typescript-eslint/no-explicit-any */
import { CLEANUP_IFRAME_TIMEOUT_IN_SECONDS, DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS } from './constants';
import { GenericError, PopupCancelledError, PopupTimeoutError, TimeoutError } from './errors';
import { AuthenticationResult, PopupConfigOptions } from './types';

export const parseQueryResult = (queryString: string): AuthenticationResult => {
    if (queryString.indexOf('#') > -1) {
        queryString = queryString.substr(0, queryString.indexOf('#'));
    }

    const queryParams = queryString.split('&');
    const parsedQuery: Record<string, any> = {};

    queryParams.forEach((qp) => {
        const [key, val] = qp.split('=');
        parsedQuery[key] = decodeURIComponent(val);
    });

    if (parsedQuery.expires_in) {
        parsedQuery.expires_in = parseInt(parsedQuery.expires_in);
    }

    return parsedQuery as AuthenticationResult;
};

export const runIframe = (authorizeUrl: string, timeoutInSeconds: number = DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) => {
    return new Promise<AuthenticationResult>((res, rej) => {
        const iframe = window.document.createElement('iframe');

        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.style.display = 'none';

        const removeIframe = () => {
            if (window.document.body.contains(iframe)) {
                window.document.body.removeChild(iframe);
                window.removeEventListener('message', iframeEventHandler, false);
            }
        };

        const timeoutSetTimeoutId = setTimeout(() => {
            rej(new TimeoutError());
            removeIframe();
        }, timeoutInSeconds * 1000);

        const iframeEventHandler = function (e: MessageEvent) {
            if (!e.data || e.data.type !== 'authorization_response') return;

            const eventSource = e.source;

            if (eventSource) {
                (eventSource as any).close();
            }

            e.data.response.error ? rej(GenericError.fromPayload(e.data.response)) : res(e.data.response);

            clearTimeout(timeoutSetTimeoutId);
            window.removeEventListener('message', iframeEventHandler, false);

            // Delay the removal of the iframe to prevent hanging loading status
            // in Chrome: https://github.com/auth0/auth0-spa-js/issues/240
            setTimeout(removeIframe, CLEANUP_IFRAME_TIMEOUT_IN_SECONDS * 1000);
        };

        window.addEventListener('message', iframeEventHandler, false);
        window.document.body.appendChild(iframe);
        iframe.setAttribute('src', authorizeUrl);
    });
};

export const openPopup = (url: string) => {
    const width = 400;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    return window.open(
        url,
        'openid-browser-client:authorize:popup',
        `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`
    );
};

export const runPopup = (config: PopupConfigOptions) => {
    return new Promise<AuthenticationResult>((resolve, reject) => {
        // Check each second if the popup is closed triggering a PopupCancelledError
        const popupTimer = setInterval(() => {
            if (config.popup && config.popup.closed) {
                clearInterval(popupTimer);
                clearTimeout(timeoutId);
                window.removeEventListener('message', popupEventListener, false);
                reject(new PopupCancelledError(config.popup));
            }
        }, 1000);

        const timeoutId = setTimeout(
            () => {
                clearInterval(popupTimer);
                reject(new PopupTimeoutError(config.popup));
                window.removeEventListener('message', popupEventListener, false);
            },
            (config.timeoutInSeconds || DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) * 1000
        );

        const popupEventListener = function (e: MessageEvent) {
            if (!e.data || e.data.type !== 'authorization_response') {
                return;
            }

            clearTimeout(timeoutId);
            clearInterval(popupTimer);
            window.removeEventListener('message', popupEventListener, false);
            config.popup.close();

            if (e.data.response.error) {
                return reject(GenericError.fromPayload(e.data.response));
            }

            resolve(e.data.response);
        };

        window.addEventListener('message', popupEventListener);
    });
};

export const createRandomString = () => {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
    let random = '';
    const randomValues = Array.from(window.crypto.getRandomValues(new Uint8Array(43)));
    randomValues.forEach((v) => (random += charset[v % charset.length]));
    return random;
};

export const encode = (value: string) => btoa(value);
export const decode = (value: string) => atob(value);

export const createQueryParams = (params: any) => {
    return Object.keys(params)
        .filter((k) => typeof params[k] !== 'undefined')
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
};

export const sha256 = async (s: string) => {
    return await window.crypto.subtle.digest({ name: 'SHA-256' }, new TextEncoder().encode(s));
};

const urlEncodeB64 = (input: string) => {
    const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
    return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
};

// https://stackoverflow.com/questions/30106476/
const decodeB64 = (input: string) =>
    decodeURIComponent(
        atob(input)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

export const urlDecodeB64 = (input: string) => decodeB64(input.replace(/_/g, '/').replace(/-/g, '+'));

export const bufferToBase64UrlEncoded = (input: number[] | Uint8Array | ArrayBuffer) => {
    const safeInput = new Uint8Array(input);
    return urlEncodeB64(window.btoa(String.fromCharCode(...Array.from(safeInput))));
};

export const validateCrypto = () => {
    if (!window.crypto) {
        throw new Error('For security reasons, `window.crypto` is required to run `openid-browser-client`.');
    }
    if (typeof window.crypto.subtle === 'undefined') {
        throw new Error(`
      openid-browser-client must run on a secure origin.
    `);
    }
};

/**
 * Returns an empty string when value is falsy, or when it's value is included in the exclude argument.
 * @param value The value to check
 * @param exclude An array of values that should result in an empty string.
 * @returns The value, or an empty string when falsy or included in the exclude argument.
 */
export function valueOrEmptyString(value: string, exclude: string[] = []) {
    return value && !exclude.includes(value) ? value : '';
}
