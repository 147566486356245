import { json2csv } from 'json-2-csv';

export const exportAsCsvFile = async (data: Record<string, unknown>[], filename?: string) => {
    const csv = json2csv(data, { preventCsvInjection: true });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = filename ?? 'download';

    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);

    a.click();
};
