import { Lazy } from '@mymahi/utility';
import { ReflectionType } from '../queries';

export const ReflectionTypeDisplayStrings = new Lazy(() => {
    return {
        [ReflectionType.STRENGTH]: $localize`My Strengths`,
        [ReflectionType.PASSION]: $localize`My Passions`,
        [ReflectionType.VALUE]: $localize`My Values`,
        [ReflectionType.LEARNING]: $localize`Ways I Learn`,
        [ReflectionType.WEAKNESS]: $localize`My Weaknesses`
    };
});
