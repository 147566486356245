import { ClientStorage } from './storage';

const TRANSACTION_STORAGE_KEY_PREFIX = 'openid.txs';

interface Transaction {
    nonce: string;
    scope: string;
    resource: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    appState?: any;
    code_verifier: string;
    redirect_uri: string;
    organizationId?: string;
    state?: string;
}

export default class TransactionManager {
    private transaction: Transaction | undefined;
    private storageKey: string;

    constructor(
        private storage: ClientStorage,
        private clientId: string
    ) {
        this.storageKey = `${TRANSACTION_STORAGE_KEY_PREFIX}.${this.clientId}`;
        this.transaction = this.storage.get(this.storageKey);
    }

    public create(transaction: Transaction) {
        this.transaction = transaction;

        this.storage.save(this.storageKey, transaction, {
            daysUntilExpire: 1
        });
    }

    public get(): Transaction | undefined {
        return this.transaction;
    }

    public remove() {
        delete this.transaction;
        this.storage.remove(this.storageKey);
    }
}
