export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        Deletable: [
            'Badge',
            'Goal',
            'GoalNote',
            'GoalTask',
            'Lesson',
            'LessonCategory',
            'LessonCategoryLocalization',
            'LessonLocalization',
            'MentorGroup',
            'MentorGroupDiscussionMessage',
            'MentorSharedResource',
            'MentorStudentNote',
            'MentorStudentTodo',
            'PathwayPlanner',
            'Project',
            'ProjectSectionComment',
            'ProjectSectionEvidence',
            'ProviderBadgeTemplate',
            'ProviderIntegrationEdgeLearning',
            'ProviderIntegrationKamar',
            'ProviderIntegrationLiss',
            'ProviderIntegrationManualSmsData',
            'ProviderIntegrationWonde',
            'ProviderMember',
            'ProviderPost',
            'ProviderProjectTemplate',
            'ProviderProjectTemplateOverviewResource',
            'ProviderProjectTemplateSection',
            'ProviderProjectTemplateSectionResource',
            'Reflection',
            'ResourceHubItem',
            'SponsorAsset',
            'SponsorCampaign',
            'SponsorCampaignAsset',
            'SponsorProvider',
            'StudentHomeworkDiaryItem',
            'StudentHomeworkDiaryMentorTask',
            'StudyProgramme',
            'ThreeGoodThings'
        ],
        NewsfeedItem: ['GoalsDueReminder', 'OpportunityListingPost', 'ProviderPost', 'SponsorPost', 'StudentHomeworkItemsDueReminder'],
        ProviderIntegration: [
            'ProviderIntegrationEdgeLearning',
            'ProviderIntegrationKamar',
            'ProviderIntegrationLiss',
            'ProviderIntegrationManualSmsData',
            'ProviderIntegrationWonde'
        ],
        ProviderIntegrationLearnerSmsData: [
            'ProviderIntegrationEdgeLearning',
            'ProviderIntegrationKamar',
            'ProviderIntegrationLiss',
            'ProviderIntegrationManualSmsData',
            'ProviderIntegrationWonde'
        ],
        Updatable: [
            'Goal',
            'GoalNote',
            'GoalTask',
            'Lesson',
            'LessonCategory',
            'LessonCategoryLocalization',
            'LessonLocalization',
            'MentorGroup',
            'MentorSharedResource',
            'MentorStudentNote',
            'MentorStudentTodo',
            'OpportunityListing',
            'PathwayPlanner',
            'ProjectSection',
            'ProjectSectionComment',
            'ProjectSectionEvidence',
            'ProviderPost',
            'ProviderProjectTemplate',
            'ProviderProjectTemplateOverviewResource',
            'ProviderProjectTemplateSection',
            'ProviderProjectTemplateSectionResource',
            'Reflection',
            'ResourceHubItem',
            'SponsorAsset',
            'SponsorCampaign',
            'SponsorCampaignAsset',
            'SponsorProvider',
            'StudentHomeworkDiaryItem',
            'StudentHomeworkDiaryMentorTask',
            'StudyProgramme',
            'ThreeGoodThings'
        ],
        WalletCard: [
            'BasicVerifiedIdCard',
            'LearnerIdVerifiableCredential',
            'LoyaltyVerifiableCredential',
            'NZStudentId',
            'VerifiedIdVerifiableCredential'
        ]
    }
};
export default result;
