export const DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS = 60;

/**
 * Thrown when network requests to the Auth server fail.
 */
export class GenericError extends Error {
    /* istanbul ignore next */
    constructor(public error: string, public error_description: string) {
        super(error_description);
        Object.setPrototypeOf(this, GenericError.prototype);
    }

    static fromPayload({ error, error_description }: { error: string; error_description: string }) {
        return new GenericError(error, error_description);
    }
}

/**
 * Thrown when silent auth times out (usually due to a configuration issue) or
 * when network requests to the Auth server timeout.
 */
export class TimeoutError extends GenericError {
    /* istanbul ignore next */
    constructor() {
        super('timeout', 'Timeout');
        //https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, TimeoutError.prototype);
    }
}

/**
 * Error thrown when the login popup times out (if the user does not complete auth)
 */
export class PopupTimeoutError extends TimeoutError {
    /* istanbul ignore next */
    constructor(public popup: Window) {
        super();
        //https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, PopupTimeoutError.prototype);
    }
}

export class PopupCancelledError extends GenericError {
    /* istanbul ignore next */
    constructor(public popup: Window) {
        super('cancelled', 'Popup closed');
        //https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, PopupCancelledError.prototype);
    }
}

export const openPopup = (url: string, target = 'popup') => {
    const width = 400;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    return window.open(url, target, `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`);
};

export const runPopup = <T>(responseType: string, popup: Window, timeoutInSeconds?: number) => {
    return new Promise<T>((resolve, reject) => {
        // eslint-disable-next-line prefer-const
        let popupEventListener: (evt: MessageEvent) => void;

        // Check each second if the popup is closed triggering a PopupCancelledError
        const popupTimer = setInterval(() => {
            if (popup && popup.closed) {
                clearInterval(popupTimer);
                clearTimeout(timeoutId);
                window.removeEventListener('message', popupEventListener, false);
                reject(new PopupCancelledError(popup));
            }
        }, 1000);

        const timeoutId = setTimeout(() => {
            clearInterval(popupTimer);
            reject(new PopupTimeoutError(popup));
            window.removeEventListener('message', popupEventListener, false);
        }, (timeoutInSeconds || DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) * 1000);

        popupEventListener = function (e: MessageEvent) {
            if (!e.data || e.data.type !== responseType) {
                return;
            }

            clearTimeout(timeoutId);
            clearInterval(popupTimer);
            window.removeEventListener('message', popupEventListener, false);
            popup.close();

            if (e.data.response.error) {
                return reject(GenericError.fromPayload(e.data.response));
            }

            resolve(e.data.response);
        };

        window.addEventListener('message', popupEventListener);
    });
};
