import { Directionality } from '@angular/cdk/bidi';
import { CdkScrollable, ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { Directive, ElementRef, NgZone, OnDestroy, OnInit, Optional } from '@angular/core';
import { Observable, Subject, animationFrameScheduler } from 'rxjs';
import { auditTime, subscribeOn, takeUntil } from 'rxjs/operators';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[cdkScrollableViewport], [cdk-scrollable-viewport]',
    exportAs: 'cdkScrollableViewport',
    standalone: true
})
export class CdkScrollableViewportDirective extends CdkScrollable implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();
    private containerRect!: DOMRect;
    private observerMap = new Map<
        string,
        {
            subject: Subject<IntersectionObserverEntry>;
            observer: IntersectionObserver;
        }
    >();

    constructor(
        private viewportRuler: ViewportRuler,
        elementRef: ElementRef<HTMLElement>,
        scrollDispatcher: ScrollDispatcher,
        ngZone: NgZone,
        @Optional() dir?: Directionality
    ) {
        super(elementRef, scrollDispatcher, ngZone, dir);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.ngZone.runOutsideAngular(() =>
            Promise.resolve().then(() => {
                this.containerRect = this.elementRef.nativeElement.getBoundingClientRect() as DOMRect;
            })
        );

        this.viewportRuler
            .change()
            .pipe(auditTime(0, animationFrameScheduler), subscribeOn(animationFrameScheduler), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.containerRect = this.elementRef.nativeElement.getBoundingClientRect() as DOMRect;
            });
    }

    public ngOnDestroy() {
        for (const [_, value] of this.observerMap) {
            value.observer.disconnect();
            value.subject.complete();
        }
        this.observerMap.clear();

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        super.ngOnDestroy();
    }

    public getViewportRect() {
        return this.containerRect;
    }

    public subscribeIntersectionElement(element: HTMLElement, rootMargin: string, thresholds: number[]) {
        const threshold = [...thresholds];
        const observerMapKey = JSON.stringify({ rootMargin, threshold });

        let observerMapEntry = this.observerMap.get(observerMapKey);
        if (observerMapEntry == null) {
            const subject$ = new Subject<IntersectionObserverEntry>();

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        subject$.next(entry);
                    });
                },
                { root: this.elementRef.nativeElement, rootMargin, threshold }
            );

            observerMapEntry = { subject: subject$, observer: observer };
            this.observerMap.set(observerMapKey, observerMapEntry);
        }

        return new Observable<IntersectionObserverEntry>((subscriber) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { subject: subject$, observer } = observerMapEntry!;

            const subscription = subject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((entry) => {
                if (entry.target === element) {
                    subscriber.next(entry);
                }
            });

            observer.observe(element);

            return {
                unsubscribe() {
                    observer.unobserve(element);
                    if (!subscription.closed) {
                        subscription.unsubscribe();
                    }
                }
            };
        });
    }
}
