export class PendingFile {
    public file: File;
    public id: string;
    public name: string;
    public size: number;
    public progress = -1;

    constructor(file: File, id: string, name: string, size?: number) {
        this.file = file;
        this.id = id;
        this.name = name;
        this.size = size || -1;
    }
}
