import { Lazy } from '@mymahi/utility';
import { ProjectFilterType } from '../queries';

export const ProjectColors = [
    {
        name: 'green',
        hexcode: '#6cc624'
    },
    {
        name: 'blue',
        hexcode: '#19affa'
    },
    {
        name: 'red',
        hexcode: '#e26d79'
    },
    {
        name: 'purple',
        hexcode: '#9f4eeb'
    },
    {
        name: 'orange',
        hexcode: '#ff9000'
    },
    {
        name: 'brown',
        hexcode: '#845932'
    },
    {
        name: 'pink',
        hexcode: '#d6306c'
    }
];

export const ProjectFilterTypeDisplayStrings = new Lazy(() => {
    return {
        [ProjectFilterType.READY_FOR_REVIEW]: $localize`Ready for review`,
        [ProjectFilterType.COMPLETED]: $localize`Completed`,
        [ProjectFilterType.PROGRESS_DESC]: $localize`In Progress`,
        [ProjectFilterType.ALL]: $localize`All`
    };
});
