import { Lazy } from '@mymahi/utility';
import { UserAgeGroup } from '../queries';

export const AgeGroupDisplayString = new Lazy(() => {
    return {
        [UserAgeGroup.UNDER_THIRTEEN]: $localize`Under 13`,
        [UserAgeGroup.THIRTEEN_TO_FIFTEEN]: $localize`13 to 15`,
        [UserAgeGroup.SIXTEEN_TO_SEVENTEEN]: $localize`16 to 17`,
        [UserAgeGroup.EIGHTEEN_TO_TWENTY]: $localize`18 to 20`,
        [UserAgeGroup.TWENTYONE_TO_TWENTYFOUR]: $localize`21 to 24`,
        [UserAgeGroup.TWENTYFIVE_AND_OVER]: $localize`25 and over`
    };
});

export const PassportCountries = [
    'AT',
    'AU',
    'BR',
    'CA',
    'CH',
    'CN',
    'DE',
    'DK',
    'ES',
    'FI',
    'FR',
    'GB',
    'HK',
    'IN',
    'IT',
    'MX',
    'NL',
    'NO',
    'NZ',
    'SE',
    'SG',
    'US',
    'ZA'
];
