import { Lazy } from '@mymahi/utility';
import { CurriculumVitaePersonalAttribute, DriversLicenceType, JobCategory, JobProfileRequiredSection, JobType } from '../queries';

export interface ICurriculumVitaePersonalAttribute {
    title: string;
    description: string;
    value: CurriculumVitaePersonalAttribute;
}

export const PersonalAttributes = new Lazy((): ICurriculumVitaePersonalAttribute[] => {
    return [
        {
            title: $localize`Accurate`,
            description: $localize`Careful, precise, free from error.`,
            value: CurriculumVitaePersonalAttribute.ACCURATE
        },
        {
            title: $localize`Adaptable`,
            description: $localize`Able to adapt oneself to new surroundings; to make suitable changes so as to fit new conditions.`,
            value: CurriculumVitaePersonalAttribute.ADAPTABLE
        },
        {
            title: $localize`Alert`,
            description: $localize`Watchful, wide awake, ready to act.`,
            value: CurriculumVitaePersonalAttribute.ALERT
        },
        {
            title: $localize`Ambitious`,
            description: $localize`Full of ambition, strong desire to do something.`,
            value: CurriculumVitaePersonalAttribute.AMBITIOUS
        },
        {
            title: $localize`Amiable`,
            description: $localize`Feeling and inspiring friendliness; lovable.`,
            value: CurriculumVitaePersonalAttribute.AMIABLE
        },
        {
            title: $localize`Analytical`,
            description: $localize`Employing analytic methods; separating things into their parts of elements.`,
            value: CurriculumVitaePersonalAttribute.ANALYTICAL
        },
        {
            title: $localize`Articulate`,
            description: $localize`Able to express oneself clearly, do not mumble.`,
            value: CurriculumVitaePersonalAttribute.ARTICULATE
        },
        {
            title: $localize`Assertive`,
            description: $localize`Insist on one's rights or opinions.`,
            value: CurriculumVitaePersonalAttribute.ASSERTIVE
        },
        {
            title: $localize`Attentive`,
            description: $localize`To notice, pay attention to, careful attention.`,
            value: CurriculumVitaePersonalAttribute.ATTENTIVE
        },
        {
            title: $localize`Broad-minded`,
            description: $localize`Being tolerant or liberal in thought or opinion.`,
            value: CurriculumVitaePersonalAttribute.BROAD_MINDED
        },
        {
            title: $localize`Businesslike`,
            description: $localize`Practical, systematic.`,
            value: CurriculumVitaePersonalAttribute.BUSINESSLIKE
        },
        {
            title: $localize`Calm`,
            description: $localize`Stillness, serenity, peaceful, undisturbed.`,
            value: CurriculumVitaePersonalAttribute.CALM
        },
        {
            title: $localize`Capable`,
            description: $localize`Having the skills/ability or fitness for.`,
            value: CurriculumVitaePersonalAttribute.CAPABLE
        },
        {
            title: $localize`Careful`,
            description: $localize`Watchful, cautious, concerned for.`,
            value: CurriculumVitaePersonalAttribute.CAREFUL
        },
        {
            title: $localize`Competent`,
            description: $localize`Adequately qualified, ability.`,
            value: CurriculumVitaePersonalAttribute.COMPETENT
        },
        {
            title: $localize`Confident`,
            description: $localize`Trusting, fully assured, belief, trust in yourself.`,
            value: CurriculumVitaePersonalAttribute.CONFIDENT
        },
        {
            title: $localize`Conscientious`,
            description: $localize`Good moral understanding of right and wrong.`,
            value: CurriculumVitaePersonalAttribute.CONSCIENTIOUS
        },
        {
            title: $localize`Consistent`,
            description: $localize`Constant to same principles, not changing.`,
            value: CurriculumVitaePersonalAttribute.CONSISTENT
        },
        {
            title: $localize`Cooperative`,
            description: $localize`Work well with others.`,
            value: CurriculumVitaePersonalAttribute.COOPERATIVE
        },
        {
            title: $localize`Dedicated`,
            description: $localize`Devoted to aims or vocation.`,
            value: CurriculumVitaePersonalAttribute.DEDICATED
        },
        {
            title: $localize`Dependable`,
            description: $localize`May be relied on.`,
            value: CurriculumVitaePersonalAttribute.DEPENDABLE
        },
        {
            title: $localize`Determined`,
            description: $localize`To-do, resolve, on a course of action.`,
            value: CurriculumVitaePersonalAttribute.DETERMINED
        },
        {
            title: $localize`Efficient`,
            description: $localize`Competent, capable, able to get results.`,
            value: CurriculumVitaePersonalAttribute.EFFICIENT
        },
        {
            title: $localize`Energetic`,
            description: $localize`Active, full of life, vigorous, an energetic worker.`,
            value: CurriculumVitaePersonalAttribute.ENERGETIC
        },
        {
            title: $localize`Enterprising`,
            description: $localize`Strong interest, great eagerness.`,
            value: CurriculumVitaePersonalAttribute.ENTERPRISING
        },
        {
            title: $localize`Flexible`,
            description: $localize`Manageable, adaptable, versatile.`,
            value: CurriculumVitaePersonalAttribute.FLEXIBLE
        },
        {
            title: $localize`Hardworking`,
            description: $localize`Done with energy, industrious.`,
            value: CurriculumVitaePersonalAttribute.HARDWORKING
        },
        {
            title: $localize`Honest`,
            description: $localize`Not lying, cheating or stealing, sincere.`,
            value: CurriculumVitaePersonalAttribute.HONEST
        },
        {
            title: $localize`Independent`,
            description: $localize`Ability to work on your own without being constantly supervised.`,
            value: CurriculumVitaePersonalAttribute.INDEPENDENT
        },
        {
            title: $localize`Industrious`,
            description: $localize`Hardworking, diligent`,
            value: CurriculumVitaePersonalAttribute.INDUSTRIOUS
        },
        {
            title: $localize`Innovative`,
            description: $localize`Make changes, introduction of something new.`,
            value: CurriculumVitaePersonalAttribute.INNOVATIVE
        },
        {
            title: $localize`Motivated`,
            description: $localize`The inner reason for any act; as, hunger might be the motive for working.`,
            value: CurriculumVitaePersonalAttribute.MOTIVATED
        },
        {
            title: $localize`Optimistic`,
            description: $localize`View that good prevails over evil.`,
            value: CurriculumVitaePersonalAttribute.OPTIMISTIC
        },
        {
            title: $localize`Organized`,
            description: $localize`place things in working order.`,
            value: CurriculumVitaePersonalAttribute.ORGANIZED
        },
        {
            title: $localize`Patient`,
            description: $localize`Calm.`,
            value: CurriculumVitaePersonalAttribute.PATIENT
        },
        {
            title: $localize`People-oriented`,
            description: $localize`Enjoy working/being with people.`,
            value: CurriculumVitaePersonalAttribute.PEOPLE_ORIENTED
        },
        {
            title: $localize`Persevering`,
            description: $localize`Steadfast pursuit to an aim, refusal to give up; continued effort.`,
            value: CurriculumVitaePersonalAttribute.PERSEVERING
        },
        {
            title: $localize`Practical`,
            description: $localize`Inclined or suited to useful action, rather than speculation. Good with hands.`,
            value: CurriculumVitaePersonalAttribute.PRACTICAL
        },
        {
            title: $localize`Productive`,
            description: $localize`Tending to produce.`,
            value: CurriculumVitaePersonalAttribute.PRODUCTIVE
        },
        {
            title: $localize`Realistic`,
            description: $localize`Practical views/policy, truth/detail, presenting people/scenes as they are.`,
            value: CurriculumVitaePersonalAttribute.REALISTIC
        },
        {
            title: $localize`Reliable`,
            description: $localize`Dependable.`,
            value: CurriculumVitaePersonalAttribute.RELIABLE
        },
        {
            title: $localize`Resourceful`,
            description: $localize`Find quick and clever ways to overcome difficulties.`,
            value: CurriculumVitaePersonalAttribute.RESOURCEFUL
        },
        {
            title: $localize`Responsible`,
            description: $localize`Capable of rational conduct, trustworthy.`,
            value: CurriculumVitaePersonalAttribute.RESPONSIBLE
        },
        {
            title: $localize`Versatile`,
            description: $localize`Turning easily or readily from occupation/job to another, changeable`,
            value: CurriculumVitaePersonalAttribute.VERSATILE
        }
    ];
});

export enum CurriculumVitaeSection {
    PERSONAL_DETAIL = 'PERSONAL_DETAIL',
    PERSONAL_STATEMENT = 'PERSONAL_STATEMENT',
    EMPLOYMENT = 'EMPLOYMENT',
    VOLUNTEER_WORK = 'VOLUNTEER_WORK',
    EDUCATION = 'EDUCATION',
    SKILLS = 'SKILLS',
    PERSONAL_ATTRIBUTES = 'PERSONAL_ATTRIBUTES',
    ACHIEVEMENT_AND_AWARDS = 'ACHIEVEMENT_AND_AWARDS',
    BADGES = 'BADGES',
    RESOURCE_HUB_ITEMS = 'RESOURCE_HUB_ITEMS',
    EXTRACURRICULAR_ACTIVITIES = 'EXTRACURRICULAR_ACTIVITIES',
    REFERENCES = 'REFERENCES'
}

export const CurriculumVitaeSectionStrings = new Lazy(() => {
    return {
        [CurriculumVitaeSection.PERSONAL_DETAIL]: $localize`Personal Details`,
        [CurriculumVitaeSection.PERSONAL_STATEMENT]: $localize`Personal Statement`,
        [CurriculumVitaeSection.EMPLOYMENT]: $localize`Employment`,
        [CurriculumVitaeSection.VOLUNTEER_WORK]: $localize`Volunteer Work`,
        [CurriculumVitaeSection.EDUCATION]: $localize`Education`,
        [CurriculumVitaeSection.SKILLS]: $localize`Skills`,
        [CurriculumVitaeSection.PERSONAL_ATTRIBUTES]: $localize`Personal Attributes`,
        [CurriculumVitaeSection.ACHIEVEMENT_AND_AWARDS]: $localize`Achievements and Awards`,
        [CurriculumVitaeSection.BADGES]: $localize`Badges`,
        [CurriculumVitaeSection.RESOURCE_HUB_ITEMS]: $localize`Resource Hub Items`,
        [CurriculumVitaeSection.EXTRACURRICULAR_ACTIVITIES]: $localize`Extracurricular Activities`,
        [CurriculumVitaeSection.REFERENCES]: $localize`References`
    };
});

export const CurriculumVitaeSectionColor = {
    [CurriculumVitaeSection.PERSONAL_DETAIL]: 'green',
    [CurriculumVitaeSection.PERSONAL_STATEMENT]: 'blue',
    [CurriculumVitaeSection.EMPLOYMENT]: 'purple',
    [CurriculumVitaeSection.VOLUNTEER_WORK]: 'violet',
    [CurriculumVitaeSection.EDUCATION]: 'pink',
    [CurriculumVitaeSection.SKILLS]: 'peach',
    [CurriculumVitaeSection.PERSONAL_ATTRIBUTES]: 'red',
    [CurriculumVitaeSection.ACHIEVEMENT_AND_AWARDS]: 'orange',
    [CurriculumVitaeSection.BADGES]: 'yellow',
    [CurriculumVitaeSection.RESOURCE_HUB_ITEMS]: 'lightyellow',
    [CurriculumVitaeSection.EXTRACURRICULAR_ACTIVITIES]: 'green',
    [CurriculumVitaeSection.REFERENCES]: 'blue'
};

export const CurriculumVitaeSectionTargetID = {
    [CurriculumVitaeSection.PERSONAL_DETAIL]: 'personalDetails',
    [CurriculumVitaeSection.PERSONAL_STATEMENT]: 'personalStatement',
    [CurriculumVitaeSection.EMPLOYMENT]: 'employment',
    [CurriculumVitaeSection.VOLUNTEER_WORK]: 'volunteerWork',
    [CurriculumVitaeSection.EDUCATION]: 'education',
    [CurriculumVitaeSection.SKILLS]: 'skills',
    [CurriculumVitaeSection.PERSONAL_ATTRIBUTES]: 'personalAttributes',
    [CurriculumVitaeSection.ACHIEVEMENT_AND_AWARDS]: 'achievementsAndAwards',
    [CurriculumVitaeSection.BADGES]: 'badges',
    [CurriculumVitaeSection.RESOURCE_HUB_ITEMS]: 'resourceHubItems',
    [CurriculumVitaeSection.EXTRACURRICULAR_ACTIVITIES]: 'extracurricularActivities',
    [CurriculumVitaeSection.REFERENCES]: 'references'
};

export const CurriculumVitaeSectionStringToValue = {
    personalDetails: [CurriculumVitaeSection.PERSONAL_DETAIL],
    personalStatement: [CurriculumVitaeSection.PERSONAL_STATEMENT],
    employment: [CurriculumVitaeSection.EMPLOYMENT],
    volunteerWork: [CurriculumVitaeSection.VOLUNTEER_WORK],
    education: [CurriculumVitaeSection.EDUCATION],
    skills: [CurriculumVitaeSection.SKILLS],
    personalAttributes: [CurriculumVitaeSection.PERSONAL_ATTRIBUTES],
    achievementsAndAwards: [CurriculumVitaeSection.ACHIEVEMENT_AND_AWARDS],
    badges: [CurriculumVitaeSection.BADGES],
    resourceHubItems: [CurriculumVitaeSection.RESOURCE_HUB_ITEMS],
    extracurricularActivities: [CurriculumVitaeSection.EXTRACURRICULAR_ACTIVITIES],
    references: [CurriculumVitaeSection.REFERENCES]
};

export interface JobProfileSection {
    title: string;
    sectionColor: string;
    scrollSectionTargetId: string;
}

export const JobProfileSections = new Lazy((): JobProfileSection[] => {
    return [
        {
            title: $localize`Personal Details`,
            sectionColor: 'green',
            scrollSectionTargetId: 'personalDetails'
        },
        {
            title: $localize`Preferred Job`,
            sectionColor: 'purple',
            scrollSectionTargetId: 'preferredJob'
        },
        {
            title: $localize`Personal Statement`,
            sectionColor: 'blue',
            scrollSectionTargetId: 'personalStatement'
        },
        {
            title: $localize`Employment`,
            sectionColor: 'darkblue',
            scrollSectionTargetId: 'employment'
        },
        {
            title: $localize`Volunteer Work`,
            sectionColor: 'violet',
            scrollSectionTargetId: 'volunteerWork'
        },
        {
            title: $localize`Education`,
            sectionColor: 'pink',
            scrollSectionTargetId: 'education'
        },
        {
            title: $localize`Skills`,
            sectionColor: 'peach',
            scrollSectionTargetId: 'skills'
        },
        {
            title: $localize`Personal Attributes`,
            sectionColor: 'red',
            scrollSectionTargetId: 'personalAttributes'
        },
        {
            title: $localize`Achievement and Awards`,
            sectionColor: 'orange',
            scrollSectionTargetId: 'achievementsAndAwards'
        },
        {
            title: $localize`Extracurricular Activities`,
            sectionColor: 'yellow',
            scrollSectionTargetId: 'extracurricularActivities'
        },
        {
            title: $localize`References`,
            sectionColor: 'lightyellow',
            scrollSectionTargetId: 'References'
        }
    ];
});

export const DriversLicenceTypeStrings = new Lazy(() => {
    return {
        [DriversLicenceType.NONE]: $localize`None`,
        [DriversLicenceType.LEARNER]: $localize`Learner`,
        [DriversLicenceType.RESTRICTED]: $localize`Restricted`,
        [DriversLicenceType.FULL]: $localize`Full`
    };
});

export const JobProfileRequiredSectionStrings = new Lazy(() => {
    return {
        [JobProfileRequiredSection.PERSONAL_DETAIL]: $localize`Personal Details`,
        [JobProfileRequiredSection.PERSONAL_STATEMENT]: $localize`Personal Statement`,
        [JobProfileRequiredSection.EDUCATION]: $localize`Education`,
        [JobProfileRequiredSection.SKILLS]: $localize`Skills`,
        [JobProfileRequiredSection.PERSONAL_ATTRIBUTES]: $localize`Personal Attributes`,
        [JobProfileRequiredSection.REFERENCES]: $localize`Referees`
    };
});

export const PreferredJobTypeStrings = new Lazy(() => {
    return {
        [JobType.FULL_TIME]: $localize`Full Time`,
        [JobType.PART_TIME]: $localize`Part Time`,
        [JobType.CASUAL]: $localize`Casual`,
        [JobType.APPRENTICE]: $localize`Apprentice`
    };
});

export const PreferredJobTypeColor = {
    [JobType.FULL_TIME]: 'blue',
    [JobType.PART_TIME]: 'green',
    [JobType.CASUAL]: 'orange',
    [JobType.APPRENTICE]: 'red'
};

export const PreferredJobCategoryStrings = new Lazy(() => {
    return {
        [JobCategory.CREATIVE_ARTISTIC_WORK]: $localize`Creative / artistic work`,
        [JobCategory.DESIGNING_THINGS]: $localize`Designing things`,
        [JobCategory.HANDS_ON_WORK_WITH_TOOLS_OR_EQUIPMENT]: $localize`Hands-on work with tools / equipment`,
        [JobCategory.OPERATING_VEHICLES]: $localize`Operating vehicles`,
        [JobCategory.PHYSICALLY_ACTIVE]: $localize`Physically active`,
        [JobCategory.PLANNING_OR_ORGANISING]: $localize`Planning / organising`,
        [JobCategory.RESEARCH_OR_ANALYSIS]: $localize`Research / analysis`,
        [JobCategory.SALES_OR_CUSTOMER_SERVICE]: $localize`Sales / customer service`,
        [JobCategory.TEAMWORK]: $localize`Teamwork`,
        [JobCategory.WORKING_OUTDOORS]: $localize`Working outdoors`,
        [JobCategory.WORKING_WITH_COMPUTERS]: $localize`Working with computers`,
        [JobCategory.WORKING_WITH_FOOD_OR_DRINKS]: $localize`Working with food/ drink`,
        [JobCategory.WORKING_WITH_NUMBERS_OR_MONEY]: $localize`Working with numbers / money`,
        [JobCategory.WORKING_WITH_PEOPLE]: $localize`Working with people`,
        [JobCategory.WORKING_WITH_PLANTS_OR_ANIMALS_OR_NATURE]: $localize`Working with plants / animals / nature`,
        [JobCategory.WRITING_AND_COMMUNICATING]: $localize`Writing and communicating`
    };
});
