export class Lazy<T> {
    private instance?: T;
    private initializer: () => T;
    get value(): T {
        if (!this.instance) {
            this.instance = this.initializer();
        }
        return this.instance;
    }
    constructor(initializer: () => T) {
        this.initializer = initializer;
    }
}
