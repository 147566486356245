import { DOCUMENT, NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService, LOCATION_KEY } from '../auth.service';

@Component({
    selector: 'authentication-root',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatCardModule, NgIf, MatProgressSpinnerModule, MatButtonModule, MatTabsModule]
})
export class AuthComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();

    public errorMessage?: string;

    public isOldBrowser = false;
    public isLoginRoute = false;
    public isCallbackRoute = false;
    public isBusy = true;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.isCallbackRoute = this.route.snapshot.url[1] == null;
        this.isLoginRoute = this.route.snapshot.url[1]?.path === 'login';
        this.isOldBrowser = typeof TextEncoder === 'undefined';
        const issuerParam = this.route.snapshot.queryParams.iss;
        const connectionParam = this.route.snapshot.queryParams.connection;
        const targetLinkUriParam = this.route.snapshot.queryParams.target_link_uri;

        if (this.isCallbackRoute) {
            this.authService
                .handleRedirectCallback()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    error: (err) => {
                        this.errorMessage = err.error_description || err.message || 'Unknown error';
                        this.isBusy = false;
                        this.router.navigateByUrl('/auth/login', { replaceUrl: true });
                    }
                });
        } else if (this.isLoginRoute) {
            this.authService
                .trySilentLogin()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    error: (_) => {
                        if (this.isOldBrowser) {
                            this.errorMessage = 'Why are you using an old browser? Get a newer one.';
                            this.isBusy = false;
                            return;
                        }

                        // Handle OIDC Third Party Initiated Login
                        if (issuerParam != null && connectionParam != null) {
                            const loc = this.document.location;
                            if (targetLinkUriParam != null && targetLinkUriParam.startsWith(loc.origin)) {
                                localStorage.setItem(LOCATION_KEY, loc.href.replace(loc.origin, ''));
                            }
                            this.authService.loginWithRedirect(connectionParam);
                        } else {
                            this.isBusy = false;
                        }
                    }
                });
        } else {
            this.authService.logout().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        }
    }

    public login(connection: string) {
        this.errorMessage = undefined;
        this.isBusy = true;
        this.authService
            .login(connection)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                error: (err) => {
                    // Don't display error if it was user canceled
                    if (!(err.error === 'cancelled')) {
                        this.errorMessage = err.error_description || err.message || 'Unknown error';
                    }
                    this.isBusy = false;
                }
            });
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
