<div class="login-content">
    <mat-card appearance="outlined">
        <mat-card-header *ngIf="errorMessage">
            <img src="/assets/svg/mymahi_icon.svg" alt="logo" />
        </mat-card-header>
        <mat-card-content>
            <div *ngIf="errorMessage" class="error-notice">
                <h3 class="error-notice-title" i18n>That's embarrassing...</h3>
                <p class="error-notice-body mat-small">
                    <span i18n>Seems like there was a problem logging you in.</span>
                    <br />
                    <span i18n>Feel free to try again and hopefully it works this time. (Fingers crossed!)</span>
                    <br />
                    <span i18n
                        >If you are still having trouble, visit our
                        <a href="https://support.mymahi.com/article/28-logging-in-to-mymahi-issues-and-how-to-fix-them"
                            >login issues support page</a
                        >.</span
                    >
                </p>
                <br />
                <div class="error-notice-message">
                    {{ errorMessage }}
                </div>
            </div>

            <div *ngIf="!errorMessage" class="login-screen">
                <img src="/assets/svg/login_screen.svg" alt="login" />
            </div>
        </mat-card-content>
        <mat-card-footer>
            <div *ngIf="isBusy" class="button-section flex flex-col gap-4">
                <div class="spinner flex flex-row items-center justify-center">
                    <mat-progress-spinner class="flex-initial" diameter="60" color="accent" mode="indeterminate"></mat-progress-spinner>
                </div>
            </div>
            <mat-tab-group *ngIf="!isOldBrowser && !isBusy" color="accent">
                <mat-tab i18n-label label="Log in">
                    <div class="button-section flex flex-col gap-4">
                        <div></div>
                        <button class="flex-initial" mat-stroked-button color="darkgrey" (click)="login('google-oauth2')">
                            <div class="relative flex flex-row items-center justify-between">
                                <div class="absolute -left-1.5 flex h-6 w-6 flex-row">
                                    <img class="w-full" src="/assets/svg/google_logo.svg" alt="logo" />
                                </div>
                                <span class="flex-1 text-contrast" i18n>Log in with Google</span>
                            </div>
                        </button>
                        <button class="flex-initial" mat-stroked-button color="darkgrey" (click)="login('microsoft')">
                            <div class="relative flex flex-row items-center justify-center">
                                <div class="absolute -left-1 flex h-5 w-5 flex-row">
                                    <img class="w-full" src="/assets/svg/microsoft_logo.svg" alt="logo" />
                                </div>
                                <span class="flex-1 text-contrast" i18n>Log in with Microsoft</span>
                            </div>
                        </button>
                        <button class="flex-initial" mat-stroked-button color="darkgrey" (click)="login('mylogin')">
                            <div class="relative flex flex-row items-center justify-center">
                                <div class="absolute -left-1 flex h-5 w-5 flex-row">
                                    <img class="w-full" src="/assets/svg/mylogin_logo.svg" alt="logo" />
                                </div>
                                <span class="flex-1 text-contrast" i18n>Log in with MyLogin</span>
                            </div>
                        </button>
                    </div>
                </mat-tab>
                <mat-tab i18n-label label="Sign up">
                    <div class="button-section flex flex-col gap-4">
                        <div></div>
                        <button class="flex-initial" mat-stroked-button color="darkgrey" (click)="login('google-oauth2')">
                            <div class="relative flex flex-row items-center justify-between">
                                <div class="absolute -left-1.5 flex h-6 w-6 flex-row">
                                    <img class="w-full" src="/assets/svg/google_logo.svg" alt="logo" />
                                </div>
                                <span class="flex-1 text-contrast" i18n>Sign up with Google</span>
                            </div>
                        </button>
                        <button class="flex-initial" mat-stroked-button color="darkgrey" (click)="login('microsoft')">
                            <div class="relative flex flex-row items-center justify-center">
                                <div class="absolute -left-1 flex h-5 w-5 flex-row">
                                    <img class="w-full" src="/assets/svg/microsoft_logo.svg" alt="logo" />
                                </div>
                                <span class="flex-1 text-contrast" i18n>Sign up with Microsoft</span>
                            </div>
                        </button>
                        <button class="flex-initial" mat-stroked-button color="darkgrey" (click)="login('mylogin')">
                            <div class="relative flex flex-row items-center justify-center">
                                <div class="absolute -left-1 flex h-5 w-5 flex-row">
                                    <img class="w-full" src="/assets/svg/mylogin_logo.svg" alt="logo" />
                                </div>
                                <span class="flex-1 text-contrast" i18n>Sign up with MyLogin</span>
                            </div>
                        </button>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-footer>
    </mat-card>
</div>
