import { Platform } from '@angular/cdk/platform';
import { Pipe, PipeTransform } from '@angular/core';

export interface CdnImageManipulateOptions {
    w?: number; // Width
    h?: number; // Height
    f?: 'jpeg' | 'png' | 'webp' | 'tiff' | 'auto'; // Format
    q?: number; // Quality
    t?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside'; // Transform
    a?: 'top' | 'left' | 'center' | 'right' | 'entropy' | 'attention'; // Align
}

export type CdnImageManipulatePresets = 'logo' | 'logoLarge' | 'avatar' | 'avatarLarge';

@Pipe({
    name: 'cdnImage',
    standalone: true
})
export class CdnImagePipe implements PipeTransform {
    constructor(private platform: Platform) {}

    /**
     * Used for manipulating images coming from a CDN
     * @param url The CDN URL to append the manipulation parameters to.
     * @param options The object with manipulation options to append, or the preset name.
     */
    public transform(url: string | null | undefined, options: CdnImageManipulateOptions | CdnImageManipulatePresets): string | undefined {
        // Safety first
        if (url == null) {
            return undefined;
        }

        const urlInstance = new URL(url);
        const autoFormat = this.platform.SAFARI ? 'jpeg' : 'webp';
        if (typeof options === 'object') {
            for (const optionName of Object.keys(options) as (keyof CdnImageManipulateOptions)[]) {
                let optionValue = options[optionName];
                if (optionValue == null) {
                    continue;
                }

                // Special case, option `f` with value `auto` is client-side only
                // We process it by changing it to `jpeg` for Safari and `webp` for non-Safari
                if (optionName === 'f' && optionValue === 'auto') {
                    optionValue = autoFormat;
                }

                urlInstance.searchParams.set(optionName, String(optionValue));
            }
        } else if (options === 'logo' || options === 'logoLarge') {
            urlInstance.searchParams.set('w', options === 'logoLarge' ? '400' : '100');
            urlInstance.searchParams.set('h', options === 'logoLarge' ? '400' : '100');
            urlInstance.searchParams.set('q', options === 'logoLarge' ? '75' : '25');
            urlInstance.searchParams.set('f', autoFormat);
        } else if (options === 'avatar' || options === 'avatarLarge') {
            urlInstance.searchParams.set('w', options === 'avatarLarge' ? '400' : '100');
            urlInstance.searchParams.set('h', options === 'avatarLarge' ? '400' : '100');
            urlInstance.searchParams.set('q', options === 'avatarLarge' ? '75' : '25');
            urlInstance.searchParams.set('f', autoFormat);
        }
        return urlInstance.toString();
    }
}
