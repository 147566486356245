import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkScrollableViewportDirective } from './cdk-scrollable-viewport.directive';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[cdkFastRender], [cdk-fast-render]',
    exportAs: 'cdkFastRender',
    standalone: true
})
export class CdkFastRenderDirective implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();
    private _rendering: boolean | null = null;
    private _viewable: boolean | null = null;

    @Output()
    public renderingChanged = new EventEmitter<boolean>();
    public get rendering() {
        return this._rendering;
    }

    @Output()
    public viewableChanged = new EventEmitter<boolean>();
    public get viewable() {
        return this._viewable;
    }

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef<HTMLElement>,
        private cdkScrollableViewport: CdkScrollableViewportDirective
    ) {}

    public ngOnInit() {
        this.cdkScrollableViewport
            .subscribeIntersectionElement(this.elementRef.nativeElement, '0px 0px 0px 0px', [0.01])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((entry) => {
                const newViewable = entry.isIntersecting;
                if (newViewable !== this._viewable) {
                    this._viewable = newViewable;
                    this.viewableChanged.emit(newViewable);
                }
            });

        this.cdkScrollableViewport
            .subscribeIntersectionElement(this.elementRef.nativeElement, '400px 0px 400px 0px', [0.01])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((entry) => {
                const newRendering = entry.isIntersecting;
                if (newRendering !== this._rendering) {
                    const element = this.elementRef.nativeElement;
                    if (newRendering === true) {
                        element.childNodes.forEach((child) => {
                            if (child instanceof HTMLElement) {
                                this.renderer.setAttribute(child, 'hidden', 'false');
                                this.renderer.removeStyle(child, 'display');
                            }
                        });
                        this.renderer.setStyle(element, 'height', '');
                    } else {
                        this.renderer.setStyle(element, 'height', `${entry.boundingClientRect.height}px`);
                        element.childNodes.forEach((child) => {
                            if (child instanceof HTMLElement) {
                                this.renderer.setAttribute(child, 'hidden', 'true');
                                this.renderer.setStyle(child, 'display', 'none');
                            }
                        });
                    }
                    this._rendering = newRendering;
                    this.renderingChanged.emit(newRendering);
                }
            });
    }

    public ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
