import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject, defer, from, merge, of, throwError } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, first, mergeMap, scan, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AUTH_CONFIG, AuthConfig } from './authConfig';
import { OpenIDBrowserClient } from './openid-browser-client';
import { openPopup, runPopup } from './utils';

export const LOCATION_KEY = '_redirect_location';

@Injectable()
export class AuthService {
    private openidBrowserClient: OpenIDBrowserClient;

    private isLoadingSubject$ = new BehaviorSubject<boolean>(true);
    private refresh$ = new Subject<void>();
    private accessToken$ = new ReplaySubject<string>(1);
    private errorSubject$ = new ReplaySubject<Error>(1);

    /**
     * Emits boolean values indicating the loading state of the SDK.
     */
    public readonly isLoading$ = this.isLoadingSubject$.asObservable();

    /**
     * Trigger used to pull User information from the OpenIDBrowserClient.
     * Triggers when the access token has changed.
     */
    private accessTokenTrigger$ = this.accessToken$.pipe(
        scan(
            (acc: { current: string | null; previous: string | null }, current: string | null) => {
                return {
                    previous: acc.current,
                    current
                };
            },
            { current: null, previous: null }
        ),
        filter(({ previous, current }) => previous !== current)
    );

    /**
     * Trigger used to pull User information from the OpenIDBrowserClient.
     * Triggers when an event occurs that needs to retrigger the User Profile information.
     * Events: Login, Access Token change and Logout
     */
    private readonly isAuthenticatedTrigger$ = this.isLoading$.pipe(
        filter((loading) => !loading),
        distinctUntilChanged(),
        switchMap(() =>
            // To track the value of isAuthenticated over time, we need to merge:
            //  - the current value
            //  - the value whenever the access token changes. (this should always be true of there is an access token
            //    but it is safer to pass this through this.openidBrowserClient.isAuthenticated() nevertheless)
            //  - the value whenever refreshState$ emits
            merge(
                defer(() => this.openidBrowserClient.isAuthenticated()),
                this.accessTokenTrigger$.pipe(mergeMap(() => this.openidBrowserClient.isAuthenticated())),
                this.refresh$.pipe(mergeMap(() => this.openidBrowserClient.isAuthenticated()))
            )
        )
    );

    /**
     * Emits boolean values indicating the authentication state of the user. If `true`, it means a user has authenticated.
     * This depends on the value of `isLoading$`, so there is no need to manually check the loading state of the SDK.
     */
    public readonly isAuthenticated$ = this.isAuthenticatedTrigger$.pipe(distinctUntilChanged(), shareReplay(1));

    /**
     * Emits details about the authenticated user, or null if not authenticated.
     */
    public readonly user$ = this.isAuthenticatedTrigger$.pipe(
        concatMap((authenticated) => (authenticated ? this.openidBrowserClient.getUser() : of(null)))
    );

    /**
     * Emits ID token claims when authenticated, or null if not authenticated.
     */
    public readonly idTokenClaims$ = this.isAuthenticatedTrigger$.pipe(
        concatMap((authenticated) => (authenticated ? this.openidBrowserClient.getIdTokenClaims() : of(null)))
    );

    /**
     * Emits errors that occur during login, or when checking for an active session on startup.
     */
    public readonly error$ = this.errorSubject$.asObservable();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
        private router: Router,
        private platform: Platform
    ) {
        const window = this.document.defaultView as Window;

        this.openidBrowserClient = new OpenIDBrowserClient({
            authority: authConfig.AUTHORITY,
            client_id: authConfig.CLIENT_ID,
            redirect_uri: `${window.location.origin}/auth_callback.html`,
            scope: authConfig.SCOPE,
            resource: authConfig.RESOURCE,
            useRefreshTokens: true,
            cacheLocation: 'localstorage'
        });

        this.isLoadingSubject$.next(false);
    }

    public trySilentLogin(): Observable<string> {
        const redirectLocation = localStorage.getItem(LOCATION_KEY) || '/';

        return of(this.openidBrowserClient).pipe(
            concatMap((client) => client.getTokenSilently()),
            tap((token) => {
                this.isAuthenticatedTrigger$
                    .pipe(first())
                    .subscribe({ next: () => this.router.navigateByUrl(redirectLocation, { replaceUrl: true }) });
                this.accessToken$.next(token);
            }),
            catchError((error) => {
                this.errorSubject$.next(error);
                this.refresh$.next();
                return throwError(() => error);
            })
        );
    }

    /**
     * WARNING: this method must be called from a synchronous context otherwise it breaks on iOS
     */
    public login(connection: string): Observable<void> {
        const window = this.document.defaultView as Window;
        const redirectLocation = localStorage.getItem(LOCATION_KEY) || '/';
        return from(
            this.openidBrowserClient
                .loginWithPopup(
                    { connection, prompt: 'login' },
                    {
                        popup: this.platform.IOS
                            ? window.open()
                            : this.platform.SAFARI || this.platform.FIREFOX
                            ? window.open('about:blank', '_blank')
                            : null,
                        timeoutInSeconds: 300
                    }
                )
                .then(() => {
                    this.isAuthenticatedTrigger$
                        .pipe(first())
                        .subscribe({ next: () => this.router.navigateByUrl(redirectLocation, { replaceUrl: true }) });
                    this.refresh$.next();
                })
        );
    }

    public loginWithRedirect(connection: string): void {
        this.openidBrowserClient.loginWithRedirect({
            connection,
            prompt: 'login',
            redirectMethod: 'replace',
            redirect_uri: `${window.location.origin}/auth`
        });
    }

    public handleRedirectCallback(): Observable<void> {
        return from(
            this.openidBrowserClient.handleRedirectCallback().then(() => {
                const redirectLocation = localStorage.getItem(LOCATION_KEY) || '/';
                this.isAuthenticatedTrigger$
                    .pipe(first())
                    .subscribe({ next: () => this.router.navigateByUrl(redirectLocation, { replaceUrl: true }) });
                this.refresh$.next();
            })
        );
    }

    /**
     * WARNING: this method must be called from a synchronous context otherwise it breaks on iOS
     */
    public linkAccount(connection: string): Observable<{ success: true }> {
        const window = this.document.defaultView as Window;

        const popup = this.platform.IOS
            ? openPopup('')
            : this.platform.SAFARI || this.platform.FIREFOX
            ? openPopup('about:blank', '_blank')
            : openPopup('');

        if (popup == null) {
            throw new Error('unable to open popup');
        }

        return from(
            this.openidBrowserClient.getIdTokenClaims().then((idToken) => {
                const url = new URL(this.authConfig.AUTHORITY);
                url.pathname = '/link';
                url.searchParams.set('client_id', this.authConfig.CLIENT_ID);
                url.searchParams.set('redirect_uri', `${window.location.origin}/auth_callback.html`);
                url.searchParams.set('connection', connection);

                if (idToken?.__raw != null) {
                    url.searchParams.set('id_token_hint', idToken.__raw);
                }

                popup.location.href = url.toString();

                return runPopup<{ success: true }>('link_account_response', popup, 300);
            })
        );
    }

    public logout(): Observable<void> {
        const window = this.document.defaultView as Window;
        return of(this.openidBrowserClient).pipe(
            concatMap((client) =>
                client.logout({
                    client_id: this.authConfig.CLIENT_ID,
                    post_logout_redirect_uri: window.location.origin
                })
            )
        );
    }

    public renewAccessToken(): Observable<string> {
        return of(this.openidBrowserClient).pipe(
            concatMap((client) => client.getTokenSilently({ ignoreCache: true })),
            tap((token) => {
                this.accessToken$.next(token);
            }),
            catchError((error) => {
                this.errorSubject$.next(error);
                this.refresh$.next();
                return throwError(() => error);
            })
        );
    }

    public getAccessToken(): Observable<string> {
        return of(this.openidBrowserClient).pipe(
            concatMap((client) => client.getTokenSilently()),
            tap((token) => {
                this.accessToken$.next(token);
            }),
            catchError((error) => {
                this.errorSubject$.next(error);
                this.refresh$.next();
                return throwError(() => error);
            })
        );
    }
}
