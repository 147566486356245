import { Lazy } from '@mymahi/utility';
import { SponsorAssetType, SponsorCampaignStatus } from '../queries';

export const SponsorCampaignStatusDisplayString = new Lazy(() => {
    return {
        [SponsorCampaignStatus.EXPIRED]: $localize`Expired`,
        [SponsorCampaignStatus.ONGOING]: $localize`Ongoing`,
        [SponsorCampaignStatus.UNPUBLISHED]: $localize`Unpublished`,
        [SponsorCampaignStatus.UPCOMING]: $localize`Upcoming`
    };
});

export const SponsorAssetTypeDisplayString = new Lazy(() => {
    return {
        [SponsorAssetType.GOAL]: $localize`Goal`,
        [SponsorAssetType.NEWSFEED]: $localize`Newsfeed`,
        [SponsorAssetType.MENTOR_GROUP]: $localize`Mentor group`,
        [SponsorAssetType.REFLECTION]: $localize`Reflection`
    };
});
