import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { AUTH_CONFIG, AuthConfig } from './authConfig';

const __routes: Routes = [
    {
        path: 'auth/login',
        component: AuthComponent
    },
    {
        path: 'auth/logout',
        component: AuthComponent
    },
    {
        path: 'auth',
        component: AuthComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(__routes),
        MatButtonModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatIconModule,
        AuthComponent
    ]
})
export class AuthenticationModule {
    public static forRoot(config: AuthConfig): ModuleWithProviders<AuthenticationModule> {
        return {
            ngModule: AuthenticationModule,
            providers: [
                {
                    provide: AUTH_CONFIG,
                    useValue: config
                },
                AuthService,
                AuthGuard
            ]
        };
    }

    constructor(private auth: AuthService) {}
}
