import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, filter, fromEvent, map, merge, switchMap, takeUntil, timer } from 'rxjs';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[longPress]',
    standalone: true
})
export class LongPressDirective implements OnInit, OnDestroy {
    private pointerDownSub!: Subscription;

    @Input()
    public duration = 500;
    @Output()
    public longPress = new EventEmitter<PointerEvent>();

    constructor(private el: ElementRef<HTMLElement>) {}

    public ngOnInit(): void {
        const pointerDown$ = fromEvent<PointerEvent>(this.el.nativeElement, 'pointerdown');
        const pointerMove$ = fromEvent<PointerEvent>(this.el.nativeElement, 'pointermove');
        const pointerUp$ = fromEvent<PointerEvent>(this.el.nativeElement, 'pointerup');
        const pointerCancel$ = fromEvent<PointerEvent>(this.el.nativeElement, 'pointercancel');

        this.pointerDownSub = pointerDown$
            .pipe(
                switchMap((x) =>
                    timer(this.duration).pipe(
                        takeUntil(
                            merge(
                                pointerMove$.pipe(
                                    filter((y) => Math.abs(x.clientX - y.clientX) > 9 || Math.abs(x.clientY - y.clientY) > 9)
                                ),
                                pointerUp$,
                                pointerCancel$
                            )
                        ),
                        map(() => x)
                    )
                )
            )
            .subscribe((x) => this.longPress.emit(x));
    }

    public ngOnDestroy(): void {
        this.pointerDownSub.unsubscribe();
    }
}
