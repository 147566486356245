import { Lazy } from '@mymahi/utility';
import { OpportunityListingRange, OpportunityListingType, OpportunitySortType } from '../queries';

export const OpportunityListingTypeDisplayStrings = new Lazy(() => {
    return {
        [OpportunityListingType.FULLTIME_JOB]: $localize`Full-time Job`,
        [OpportunityListingType.PARTTIME_JOB]: $localize`Part-time Job`,
        [OpportunityListingType.APPRENTICESHIP]: $localize`Apprenticeship`,
        [OpportunityListingType.CASUAL_WORK]: $localize`Casual Work`
    };
});

export const OpportunityListingRangeDisplayStrings = new Lazy(() => {
    return {
        [OpportunityListingRange.INTERNATIONAL]: $localize`International`,
        [OpportunityListingRange.NATIONAL]: $localize`National`,
        [OpportunityListingRange.REGIONAL]: $localize`Regional`
    };
});

export const OpportunitySortTypeStrings = new Lazy(() => {
    return {
        [OpportunitySortType.LATEST]: $localize`Latest`,
        [OpportunitySortType.HIGH_PAYRATE]: $localize`High Pay Rate`,
        [OpportunitySortType.LOW_PAYRATE]: $localize`Low Pay Rate`
    };
});
