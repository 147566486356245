import { Lazy } from '@mymahi/utility';
import { ResourceHubItemCategory, ResourceHubItemType } from '../queries';

export const ResourceHubItemCategoryDisplayStrings = new Lazy(() => {
    return {
        [ResourceHubItemCategory.CERTIFICATE]: $localize`Certificate`,
        [ResourceHubItemCategory.EVIDENCE]: $localize`Evidence`,
        [ResourceHubItemCategory.LICENSE]: $localize`Licence`,
        [ResourceHubItemCategory.OTHER]: $localize`Other`
    };
});

export const ResourceHubItemTypeDisplayStrings = new Lazy(() => {
    return {
        [ResourceHubItemType.IMAGE]: $localize`Image`,
        [ResourceHubItemType.AUDIO]: $localize`Audio`,
        [ResourceHubItemType.VIDEO]: $localize`Video`,
        [ResourceHubItemType.DOCUMENT]: $localize`Document`,
        [ResourceHubItemType.OTHER]: $localize`Other`
    };
});

export const ResourceHubItemCategoryColors = {
    [ResourceHubItemCategory.CERTIFICATE]: 'blue',
    [ResourceHubItemCategory.EVIDENCE]: 'orange',
    [ResourceHubItemCategory.LICENSE]: 'green',
    [ResourceHubItemCategory.OTHER]: 'grey'
};

export const ResourceHubItemTypeColors = {
    [ResourceHubItemType.IMAGE]: 'green',
    [ResourceHubItemType.AUDIO]: 'purple',
    [ResourceHubItemType.VIDEO]: 'red',
    [ResourceHubItemType.DOCUMENT]: 'orange',
    [ResourceHubItemType.OTHER]: 'grey'
};
