<ng-container *ngIf="shouldRenderNavigation">
    <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="!shouldRenderNavigation">
    <div cdkScrollableViewport class="app-wrapper">
        <app-network-status></app-network-status>
        <router-outlet></router-outlet>
    </div>
</ng-container>
