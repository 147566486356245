import { MessageId, TargetMessage } from '@angular/localize';
import xliff from 'xliff';

export async function parseTranslationsForLocalize(translations: string): Promise<Record<MessageId, TargetMessage>> {
    const parserResult = await xliff.xliff12ToJs(translations);
    const xliffContent = parserResult.resources['ng2.template'];

    return Object.keys(xliffContent).reduce((result: Record<MessageId, TargetMessage>, current: string) => {
        const translation = xliffContent[current].target;
        if (typeof translation === 'string') {
            result[current] = translation;
        } else if (Array.isArray(translation)) {
            result[current] = translation
                .map((entry) => (typeof entry === 'string' ? entry : `{$${entry.Standalone.id}}`))
                .join('')
                .replaceAll(/^\{[\s\n]*VAR.*\}$/gm, (x) => x.replaceAll(/(\{\$([\w\d_]+)\})+/gm, '{$2}'));
        } else {
            console.warn('this is probably an error', xliffContent[current]);
            result[current] = translation.Standalone['equiv-text'];
        }
        return result;
    }, {});
}
