import { Lazy } from '@mymahi/utility';
import { ProviderIntegrationType, ProviderSortType, ProviderType } from '../queries';

export const MYMAHI_PROVIDER_ID = '2d1531e2-3b0f-4d32-a4e8-99e9aec69a69';

export const ProviderTypeDisplayStrings = new Lazy(() => {
    return {
        [ProviderType.PRIMARYSCHOOL]: $localize`Primary School`,
        [ProviderType.MIDDLESCHOOL]: $localize`Middle School`,
        [ProviderType.HIGHSCHOOL]: $localize`High School`,
        [ProviderType.TERTIARY]: $localize`Tertiary`,
        [ProviderType.COMMERCIAL]: $localize`Commercial`,
        [ProviderType.OTHER]: $localize`Other`
    };
});

export const ProviderRoleDisplayStrings = new Lazy(() => {
    return {
        administrator: $localize`Administrator`,
        employment_manager: $localize`Employment Manager`,
        mentor: $localize`Mentor`,
        mentor_badges_manager: $localize`Mentor Badges Manager`,
        mentor_rewards_manager: $localize`Mentor Rewards Manager`,
        mentor_manager: $localize`Mentor Manager`,
        newsfeed_manager: $localize`Newsfeed Manager`,
        opportunity_manager: $localize`Opportunity Manager`,
        study_programme_manager: $localize`Course Manager`
    };
});

export const ProviderModuleDisplayStrings = new Lazy(() => {
    return {
        attendance: $localize`Attendance`,
        badge_templates: $localize`Badge Templates`,
        employment_dashboard: $localize`Employment Dashboard`,
        integration_edge_learning: $localize`Edge Learning Integration`,
        integration_kamar: $localize`KAMAR Integration`,
        integration_liss: $localize`LISS Integration`,
        integration_manual_sms_data: $localize`Manual SMS Data Integration`,
        integration_wonde: $localize`Wonde Integration`,
        learner_id: $localize`Learner ID`,
        lessons: $localize`Lessons`,
        mentor_dashboard: $localize`Mentor Dashboard`,
        mentor_rewards: $localize`Mentor Rewards`,
        my_school: $localize`My School`,
        newsfeed: $localize`Newsfeed`,
        nz_student_id: $localize`NZ Student ID (DEPRECATED)`,
        opportunity: $localize`Opportunities`,
        project_templates: $localize`Project Templates`,
        study_programme: $localize`Courses`
    };
});

export const ProviderSortTypeStrings = new Lazy(() => {
    return {
        [ProviderSortType.SUGGESTED]: $localize`Suggested`,
        [ProviderSortType.ALPHABETICAL_A_Z]: $localize`A to Z`,
        [ProviderSortType.ALPHABETICAL_Z_A]: $localize`Z to A`
    };
});

export const ProviderIntegrationTypeStrings = new Lazy(() => {
    return {
        [ProviderIntegrationType.EDGE_LEARNING]: $localize`Edge Learning`,
        [ProviderIntegrationType.KAMAR]: $localize`KAMAR`,
        [ProviderIntegrationType.LISS]: $localize`LISS`,
        [ProviderIntegrationType.MANUAL_SMS_DATA]: $localize`Manual SMS Data`,
        [ProviderIntegrationType.WONDE]: $localize`Wonde`
    };
});
