import { InjectionToken } from '@angular/core';

export const AUTH_CONFIG: InjectionToken<AuthConfig> = new InjectionToken<AuthConfig>('Authentication configuration');

export interface AuthConfig {
    AUTHORITY: string;
    CLIENT_ID: string;
    RESOURCE: string;
    SCOPE: string;
}
