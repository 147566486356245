import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { APIConfig, API_CONFIG } from './apiConfig';
import { QueryService } from './query.service';

@NgModule({
    imports: [CommonModule, ApolloModule]
})
export class APIModule {
    public static forRoot(config: APIConfig): ModuleWithProviders<APIModule> {
        return {
            ngModule: APIModule,
            providers: [
                {
                    provide: API_CONFIG,
                    useValue: config
                },
                QueryService
            ]
        };
    }

    constructor(private query: QueryService) {}
}
