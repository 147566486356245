import { ContentChildren, Directive, ElementRef, EventEmitter, HostListener, Input, Output, QueryList } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[scrollTarget]',
    standalone: true
})
export class ScrollTargetDirective {
    @Input('scrollTarget')
    public scrollTargetId!: string;

    constructor(public elementRef: ElementRef) {}
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[scrollService]',
    standalone: true
})
export class ScrollServiceDirective {
    @ContentChildren(ScrollTargetDirective)
    public targets!: QueryList<ScrollTargetDirective>;

    @Output() public sectionChange = new EventEmitter<string | null>();
    public currentSection: string | null = null;

    constructor(private _el: ElementRef) {}

    @HostListener('scroll', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onScroll(event: any) {
        let newCurrentSection: string | null = null;
        const children = this._el.nativeElement.children;
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop;
        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            const foundSection = this.targets.find((s) => s.elementRef.nativeElement === element);
            if (foundSection != null) {
                if (element.offsetTop - parentOffset <= scrollTop) {
                    newCurrentSection = foundSection.scrollTargetId;
                }
            }
        }
        if (newCurrentSection !== this.currentSection) {
            this.currentSection = newCurrentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }

    public scrollTo(id: string) {
        const foundSection = this.targets.find((s) => s.scrollTargetId === id);
        if (foundSection != null) {
            foundSection.elementRef.nativeElement.scrollIntoView();
        }
    }
}
