import { Lazy } from '@mymahi/utility';
import { ConnectedUserSharedActivityType } from '../queries';

export interface IShareableActivity {
    title: string;
    description: string;
    type: ConnectedUserSharedActivityType;
}

export const ShareableActivities = new Lazy((): IShareableActivity[] => {
    return [
        {
            title: $localize`Timetable`,
            description: $localize`Personal school timetable / calendar`,
            type: ConnectedUserSharedActivityType.TIMETABLE
        },
        {
            title: $localize`Homework Diary`,
            description: $localize`Personal school homework diary`,
            type: ConnectedUserSharedActivityType.HOMEWORK_DIARY
        },
        {
            title: $localize`Goals`,
            description: $localize`Users goals`,
            type: ConnectedUserSharedActivityType.GOALS
        },
        {
            title: $localize`Reflections`,
            description: $localize`Self reflections`,
            type: ConnectedUserSharedActivityType.REFLECTIONS
        },
        {
            title: $localize`Curriculum Vitae`,
            description: $localize`Record of work and personal achievements`,
            type: ConnectedUserSharedActivityType.CURRICULUM_VITAE
        },
        {
            title: $localize`Pathway Planners`,
            description: $localize`Mapping out the future`,
            type: ConnectedUserSharedActivityType.PATHWAY_PLANNERS
        },
        {
            title: $localize`Saved Courses`,
            description: $localize`Courses of interest`,
            type: ConnectedUserSharedActivityType.SAVED_COURSES
        },
        {
            title: $localize`Projects`,
            description: $localize`Projects past and present`,
            type: ConnectedUserSharedActivityType.PROJECTS
        },
        {
            title: $localize`Daily Notices`,
            description: $localize`School / provider notices`,
            type: ConnectedUserSharedActivityType.DAILY_NOTICES
        },
        {
            title: $localize`Assessment Results`,
            description: $localize`School / provider results`,
            type: ConnectedUserSharedActivityType.ASSESSMENT_RESULTS
        },
        {
            title: $localize`Attendance`,
            description: $localize`School / provider attendance records`,
            type: ConnectedUserSharedActivityType.ATTENDANCE
        },
        {
            title: $localize`Rewards`,
            description: $localize`Points / rewards from mentors, to be claimed`,
            type: ConnectedUserSharedActivityType.REWARDS
        }
    ];
});
