import { Lazy } from '@mymahi/utility';
import { PathwayPlannerInformationType } from '../queries';

export const PathwayPlannerInformationTypeDisplayStrings = new Lazy(() => {
    return {
        [PathwayPlannerInformationType.NEEDED_EXPERIENCE]: $localize`Needed Experience`,
        [PathwayPlannerInformationType.NEEDED_QUALIFICATIONS]: $localize`Needed Qualifications`,
        [PathwayPlannerInformationType.NEEDED_SPECIALIST_SKILLS]: $localize`Needed Specialist Skills`,
        [PathwayPlannerInformationType.NEEDED_PERSONAL_CAPABILITIES]: $localize`Needed Personal Capabilities`,
        [PathwayPlannerInformationType.EXTRACURRICULAR]: $localize`Extracurricular`,
        [PathwayPlannerInformationType.SUBJECTS]: $localize`Subjects`
    };
});
