import { Lazy } from '@mymahi/utility';
import { GoalType } from '../queries';

export const GoalTypeDisplayStrings = new Lazy(() => {
    return {
        [GoalType.DEVELOPING_ME]: $localize`Developing Me`,
        [GoalType.HEALTH_AND_WELLBEING]: $localize`Wellbeing`,
        [GoalType.ACADEMIC]: $localize`Academic`,
        [GoalType.CAREER]: $localize`Career`,
        [GoalType.ENVIRONMENT]: $localize`Environment`,
        [GoalType.EXTRA_CURRICULAR]: $localize`Other`
    };
});
