import { Pipe, PipeTransform } from '@angular/core';
import shortUuid from 'short-uuid';

const URL_ALPHABET = 'ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW';
const UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;

const translator = shortUuid(URL_ALPHABET);

export function urlEncodeUuid(uuid: string) {
    return translator.fromUUID(uuid);
}

export function urlDecodeUuid(shortId: string) {
    // For backwards compat, if we get passed a uuid just return it directly
    if (UUID_REGEX.test(shortId)) {
        return shortId;
    }
    return translator.toUUID(shortId);
}

@Pipe({
    name: 'urlEncodeUuid',
    standalone: true
})
export class UrlEncodeUuidPipe implements PipeTransform {
    public transform(uuid: string): string {
        return urlEncodeUuid(uuid);
    }
}
