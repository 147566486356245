/* eslint-disable no-useless-escape */
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const PHONE_REGEX = /^[0-9\.\-\s\+]{0,}$/i;
const URL_REGEX =
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/iu;

const isFormGroup = (c: AbstractControl): c is FormGroup => c instanceof FormGroup;
const isFormArray = (c: AbstractControl): c is FormArray => c instanceof FormArray;

export class CustomValidators {
    public static year(c: AbstractControl): ValidationErrors | null {
        const numValue = Number(c.value);
        const currentYear = new Date().getFullYear();
        const minYear = 1900;
        const maxYear = currentYear + 10;
        const isValid = !isNaN(numValue) && numValue >= minYear && numValue <= maxYear;
        const message = {
            year: {
                message: `The year must be a valid number between ${minYear} and ${maxYear}`
            }
        };
        return isValid ? null : message;
    }

    public static atLeastOne(validator: ValidatorFn, controls: string[] | null = null) {
        return (group: FormGroup): ValidationErrors | null => {
            if (controls == null) {
                controls = Object.keys(group.controls);
            }

            const hasAtLeastOne = group && group.controls && controls.some((k) => !validator(group.controls[k]));

            if (!hasAtLeastOne) {
                return {
                    atLeastOne: {
                        text: `At least one ${validator.name === 'required' ? 'is' : 'should be'} ${validator.name}: ${controls.join(', ')}`
                    }
                };
            }

            return null;
        };
    }

    public static atLeastOneRequired(c: AbstractControl): ValidationErrors | null {
        if (isFormGroup(c)) {
            const theOne = Object.keys(c.controls).find((key) => c.controls[key].value != null && c.controls[key].value.length > 0);
            if (!theOne) {
                return {
                    atLeastOneRequired: {
                        text: 'At least one field should be filled out'
                    }
                };
            }
        }
        return null;
    }

    public static atLeastOneChecked(c: AbstractControl): ValidationErrors | null {
        if (isFormArray(c)) {
            const some = c.controls.some((control) => control.value === true);
            if (!some) {
                return {
                    atLeastOneChecked: {
                        text: 'At least one checkbox should be ticked'
                    }
                };
            }
        }
        return null;
    }

    public static object(c: AbstractControl): ValidationErrors | null {
        const message = {
            object: {
                message: 'Please select a value'
            }
        };

        return typeof c.value === 'object' ? null : message;
    }

    public static email(c: AbstractControl): ValidationErrors | null {
        const value = c.value;
        const isValid = value != null && value !== '' ? EMAIL_REGEX.test(c.value) : true;
        const message = {
            email: {
                message: `Invalid email address`
            }
        };
        return isValid ? null : message;
    }

    public static phone(c: AbstractControl): ValidationErrors | null {
        const value = c.value;
        const isValid = value != null && value !== '' ? PHONE_REGEX.test(value) : true;
        const message = {
            phone: {
                message: `Invalid phone number`
            }
        };
        return isValid ? null : message;
    }

    public static url(c: AbstractControl): ValidationErrors | null {
        const value = c.value;
        const isValid = value != null && value !== '' ? URL_REGEX.test(c.value) : true;
        const message = {
            url: {
                message: `Invalid url`
            }
        };
        return isValid ? null : message;
    }

    public static dob(c: AbstractControl): ValidationErrors | null {
        const inputDate = new Date(c.value);
        const now = new Date();
        const isValid = inputDate < now;
        const message = {
            year: {
                message: `Cannot input date of birth before current time`
            }
        };
        return isValid ? null : message;
    }

    public static minLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (CustomValidators.isPresent(Validators.required(control))) {
                return null;
            }
            const v: string = control.value ? control.value : '';
            return v.trim().length < minLength ? { minlength: { requiredLength: minLength, actualLength: v.trim().length } } : null;
        };
    }

    private static isPresent(obj: unknown): boolean {
        return obj !== undefined && obj !== null;
    }
}
