import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DisplayService {
    private themeChangedSubject = new Subject<'light' | 'dark'>();
    private textDensityChangedSubject = new Subject<number>();
    private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
    private lightMediaQuery = this.document.defaultView.matchMedia('(prefers-color-scheme: light)');
    private darkMediaQuery = this.document.defaultView.matchMedia('(prefers-color-scheme: dark)');

    public currentThemeTitle: string;
    public isOverriding = false;
    public themeLight = this.lightMediaQuery.matches;
    public themeDark = this.darkMediaQuery.matches;
    public themeCurrent: 'light' | 'dark' = this.themeLight ? 'light' : 'dark';
    public themePrevious: 'light' | 'dark' = this.themeCurrent;
    public themeChanged$ = this.themeChangedSubject.asObservable();

    public textDensity = 0;
    public textDensityChanged$ = this.textDensityChangedSubject.asObservable();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2
    ) {
        if (localStorage.getItem('theme') != null) {
            const theme = localStorage.getItem('theme') as 'light' | 'dark' | 'system';
            switch (theme) {
                case 'light':
                    this.isOverriding = true;
                    this.themeLight = true;
                    this.themeDark = false;
                    break;
                case 'dark':
                    this.isOverriding = true;
                    this.themeLight = false;
                    this.themeDark = true;
                    break;
                case 'system':
                    this.isOverriding = false;
                    this.themeLight = this.lightMediaQuery.matches;
                    this.themeDark = this.darkMediaQuery.matches;
                    break;
            }
            this.themePrevious = this.themeCurrent;
            this.themeCurrent = this.themeLight ? 'light' : 'dark';
            this.themeChangedSubject.next(this.themeCurrent);
        }

        if (localStorage.getItem('text-density') != null) {
            this.textDensity = parseInt(localStorage.getItem('text-density') as string, 10);
        }

        this.updateThemeClasses();
        this.updateTextDensityStyles();

        fromEvent(this.lightMediaQuery, 'change').subscribe({
            next: (event: MediaQueryListEvent) => {
                if (event.matches && !this.isOverriding) {
                    this.themeLight = true;
                    this.themeDark = false;
                    this.themePrevious = this.themeCurrent;
                    this.themeCurrent = 'light';
                    this.themeChangedSubject.next(this.themeCurrent);
                    this.updateThemeClasses();
                }
            },
            error: (err) => {
                console.log(err);
            }
        });
        fromEvent(this.darkMediaQuery, 'change').subscribe({
            next: (event: MediaQueryListEvent) => {
                if (event.matches && !this.isOverriding) {
                    this.themeLight = false;
                    this.themeDark = true;
                    this.themePrevious = this.themeCurrent;
                    this.themeCurrent = 'dark';
                    this.themeChangedSubject.next(this.themeCurrent);
                    this.updateThemeClasses();
                }
            },
            error: (err) => {
                console.log(err);
            }
        });
    }

    private updateThemeClasses() {
        if (this.themeLight) {
            this.renderer.addClass(this.document.body, 'theme-light');
            this.renderer.removeClass(this.document.body, 'theme-dark');
        } else {
            this.renderer.addClass(this.document.body, 'theme-dark');
            this.renderer.removeClass(this.document.body, 'theme-light');
        }
    }

    private updateTextDensityStyles() {
        if (this.textDensity > 0) {
            this.renderer.setStyle(this.document.body, '--text-density', this.textDensity, RendererStyleFlags2.DashCase);
        } else {
            this.renderer.removeStyle(this.document.body, '--text-density', RendererStyleFlags2.DashCase);
        }
    }

    public overrideThemeLight() {
        this.isOverriding = true;
        this.themeLight = true;
        this.themeDark = false;
        this.themePrevious = this.themeCurrent;
        this.themeCurrent = 'light';
        this.themeChangedSubject.next(this.themeCurrent);
        this.updateThemeClasses();
        localStorage.setItem('theme', 'light');
    }

    public overrideThemeDark() {
        this.isOverriding = true;
        this.themeLight = false;
        this.themeDark = true;
        this.themePrevious = this.themeCurrent;
        this.themeCurrent = 'dark';
        this.themeChangedSubject.next(this.themeCurrent);
        this.updateThemeClasses();
        localStorage.setItem('theme', 'dark');
    }

    public clearThemeOverride() {
        this.isOverriding = false;
        this.themeLight = this.lightMediaQuery.matches;
        this.themeDark = this.darkMediaQuery.matches;
        this.themePrevious = this.themeCurrent;
        this.themeCurrent = this.themeLight ? 'light' : 'dark';
        this.themeChangedSubject.next(this.themeCurrent);
        this.updateThemeClasses();
        localStorage.setItem('theme', 'system');
    }

    public setTextDensity(density: number) {
        this.textDensity = density;
        this.textDensityChangedSubject.next(this.textDensity);
        this.updateTextDensityStyles();
        localStorage.setItem('text-density', this.textDensity.toString());
    }
}
