import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService, LOCATION_KEY } from '../auth.service';

@Injectable()
export class AuthGuard {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private authService: AuthService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authService.isAuthenticated$.pipe(
            map((authenticated) => {
                if (!authenticated) {
                    localStorage.setItem(LOCATION_KEY, state.url);
                    this.router.navigateByUrl('/auth/login', { replaceUrl: true });
                    return false;
                } else {
                    return true;
                }
            })
        );
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.canActivate(route, state);
    }

    public canLoad(_route: Route, _segments: UrlSegment[]): Observable<boolean | UrlTree> {
        return this.authService.isAuthenticated$.pipe(
            map((authenticated) => {
                if (!authenticated) {
                    const loc = this.document.location;
                    localStorage.setItem(LOCATION_KEY, loc.href.replace(loc.origin, ''));
                    this.router.navigateByUrl('/auth/login', { replaceUrl: true });
                    return false;
                } else {
                    return true;
                }
            })
        );
    }
}
