import { StudyProgrammeType } from '../queries';

export const StudyProgrammeTypeDisplayStrings = {
    [StudyProgrammeType.STANDARD]: 'Standard',
    [StudyProgrammeType.NZ_STP]: 'NZ Secondary-Tertiary Pathway'
};

export const MOEEthnicityCodes = [
    { code: '10000', title: 'European nfd' },
    { code: '11111', title: 'New Zealand European' },
    { code: '12100', title: 'British nfd' },
    { code: '12111', title: 'Celtic nfd' },
    { code: '12112', title: 'Channel Islander' },
    { code: '12113', title: 'Cornish' },
    { code: '12114', title: 'English' },
    { code: '12116', title: 'Irish' },
    { code: '12117', title: 'Manx' },
    { code: '12119', title: 'Scottish' },
    { code: '12121', title: 'Welsh' },
    { code: '12199', title: 'British nec' },
    { code: '12211', title: 'Dutch' },
    { code: '12311', title: 'Greek' },
    { code: '12411', title: 'Polish' },
    { code: '12500', title: 'South Slav nfd' },
    { code: '12511', title: 'Croatian' },
    { code: '12512', title: 'Dalmatian' },
    { code: '12513', title: 'Macedonian' },
    { code: '12514', title: 'Serbian' },
    { code: '12515', title: 'Slovenian' },
    { code: '12516', title: 'Bosnian' },
    { code: '12599', title: 'South Slav nec' },
    { code: '12611', title: 'Italian' },
    { code: '12711', title: 'German' },
    { code: '12811', title: 'Australian' },
    { code: '12911', title: 'Albanian' },
    { code: '12912', title: 'Armenian' },
    { code: '12913', title: 'Austrian' },
    { code: '12914', title: 'Belgian' },
    { code: '12915', title: 'Bulgarian' },
    { code: '12916', title: 'Belorussian' },
    { code: '12918', title: 'Cypriot nfd' },
    { code: '12919', title: 'Czech' },
    { code: '12920', title: 'Danish' },
    { code: '12921', title: 'Estonian' },
    { code: '12922', title: 'Finnish' },
    { code: '12923', title: 'Flemish' },
    { code: '12924', title: 'French' },
    { code: '12926', title: 'Hungarian' },
    { code: '12927', title: 'Icelandic' },
    { code: '12928', title: 'Latvian' },
    { code: '12929', title: 'Lithuanian' },
    { code: '12930', title: 'Maltese' },
    { code: '12931', title: 'Norwegian' },
    { code: '12932', title: 'Portuguese' },
    { code: '12933', title: 'Romanian' },
    { code: '12934', title: 'Gypsy' },
    { code: '12935', title: 'Russian' },
    { code: '12937', title: 'Slavic' },
    { code: '12938', title: 'Slovak' },
    { code: '12939', title: 'Spanish' },
    { code: '12940', title: 'Swedish' },
    { code: '12941', title: 'Swiss' },
    { code: '12942', title: 'Ukrainian' },
    { code: '12943', title: 'American' },
    { code: '12945', title: 'Canadian' },
    { code: '12947', title: 'New Caledonian' },
    { code: '12948', title: 'South African European' },
    { code: '12949', title: 'Afrikaner' },
    { code: '12950', title: 'Zimbabwean European' },
    { code: '12999', title: 'European nec' },
    { code: '21111', title: 'Māori' },
    { code: '30000', title: 'Pacific Peoples nfd' },
    { code: '31111', title: 'Samoan' },
    { code: '32100', title: 'Cook Islands Maori' },
    { code: '33111', title: 'Tongan' },
    { code: '34111', title: 'Niuean' },
    { code: '35111', title: 'Tokelauan' },
    { code: '36111', title: 'Fijian' },
    { code: '37112', title: 'Indigenous Australian' },
    { code: '37122', title: 'Hawaiian' },
    { code: '37124', title: 'Kiribati' },
    { code: '37130', title: 'Nauruan' },
    { code: '37135', title: 'Papua New Guinean' },
    { code: '37137', title: 'Pitcairn Islander' },
    { code: '37138', title: 'Rotuman' },
    { code: '37140', title: 'Tahitian' },
    { code: '37141', title: 'Solomon Islander' },
    { code: '37144', title: 'Tuvaluan' },
    { code: '37145', title: 'Ni Vanuatu' },
    { code: '37199', title: 'Pacific Peoples nec' },
    { code: '40000', title: 'Asian nfd' },
    { code: '41000', title: 'Southeast Asian nfd' },
    { code: '41111', title: 'Filipino' },
    { code: '41211', title: 'Cambodian' },
    { code: '41311', title: 'Vietnamese' },
    { code: '41411', title: 'Burmese' },
    { code: '41412', title: 'Indonesian' },
    { code: '41413', title: 'Lao' },
    { code: '41414', title: 'Malay' },
    { code: '41415', title: 'Thai' },
    { code: '41416', title: 'Karen' },
    { code: '41417', title: 'Chin' },
    { code: '41499', title: 'Southeast Asian nec' },
    { code: '42100', title: 'Chinese nfd' },
    { code: '42111', title: 'Hong Kong Chinese' },
    { code: '42112', title: 'Cambodian Chinese' },
    { code: '42113', title: 'Malaysian Chinese' },
    { code: '42114', title: 'Singaporean Chinese' },
    { code: '42115', title: 'Vietnamese Chinese' },
    { code: '42116', title: 'Taiwanese' },
    { code: '42199', title: 'Chinese nec' },
    { code: '43100', title: 'Indian nfd' },
    { code: '43111', title: 'Bengali' },
    { code: '43112', title: 'Fijian Indian' },
    { code: '43114', title: 'Indian Tamil' },
    { code: '43115', title: 'Punjabi' },
    { code: '43116', title: 'Sikh' },
    { code: '43117', title: 'Anglo Indian' },
    { code: '43118', title: 'Malaysian Indian' },
    { code: '43119', title: 'South African Indian' },
    { code: '43199', title: 'Indian nec' },
    { code: '44100', title: 'Sri Lankan nfd' },
    { code: '44111', title: 'Sinhalese' },
    { code: '44112', title: 'Sri Lankan Tamil' },
    { code: '44199', title: 'Sri Lankan nec' },
    { code: '44211', title: 'Japanese' },
    { code: '44311', title: 'Korean' },
    { code: '44411', title: 'Afghani' },
    { code: '44412', title: 'Bangladeshi' },
    { code: '44413', title: 'Nepalese' },
    { code: '44414', title: 'Pakistani' },
    { code: '44415', title: 'Tibetan' },
    { code: '44416', title: 'Eurasian' },
    { code: '44417', title: 'Bhutanese' },
    { code: '44418', title: 'Maldivian' },
    { code: '44419', title: 'Mongolian' },
    { code: '44499', title: 'Asian nec' },
    { code: '51100', title: 'Middle Eastern nfd' },
    { code: '51111', title: 'Algerian' },
    { code: '51112', title: 'Arab' },
    { code: '51113', title: 'Assyrian' },
    { code: '51114', title: 'Egyptian' },
    { code: '51115', title: 'Iranian/Persian' },
    { code: '51116', title: 'Iraqi' },
    { code: '51117', title: 'Israeli/Jewish' },
    { code: '51118', title: 'Jordanian' },
    { code: '51119', title: 'Kurd' },
    { code: '51120', title: 'Lebanese' },
    { code: '51122', title: 'Moroccan' },
    { code: '51124', title: 'Palestinian' },
    { code: '51125', title: 'Syrian' },
    { code: '51127', title: 'Turkish' },
    { code: '51199', title: 'Middle Eastern nec' },
    { code: '52100', title: 'Latin American nfd' },
    { code: '52111', title: 'Argentinian' },
    { code: '52112', title: 'Bolivian' },
    { code: '52113', title: 'Brazilian' },
    { code: '52114', title: 'Chilean' },
    { code: '52115', title: 'Colombian' },
    { code: '52118', title: 'Ecuadorian' },
    { code: '52123', title: 'Mexican' },
    { code: '52127', title: 'Peruvian' },
    { code: '52128', title: 'Puerto Rican' },
    { code: '52129', title: 'Uruguayan' },
    { code: '52130', title: 'Venezuelan' },
    { code: '52199', title: 'Latin American nec' },
    { code: '53100', title: 'African nfd' },
    { code: '53113', title: 'Jamaican' },
    { code: '53114', title: 'Kenyan' },
    { code: '53115', title: 'Nigerian' },
    { code: '53116', title: 'African American' },
    { code: '53118', title: 'Caribbean' },
    { code: '53119', title: 'Somali' },
    { code: '53120', title: 'Eritrean' },
    { code: '53121', title: 'Ethiopian' },
    { code: '53122', title: 'Ghanaian' },
    { code: '53123', title: 'Burundian' },
    { code: '53124', title: 'Congolese' },
    { code: '53125', title: 'Sudanese' },
    { code: '53126', title: 'Zambian' },
    { code: '53127', title: 'Other Zimbabwean' },
    { code: '53199', title: 'African nec' },
    { code: '61113', title: 'Indigenous American' },
    { code: '61115', title: 'Mauritian' },
    { code: '61116', title: 'Seychellois' },
    { code: '61117', title: 'Other South African' },
    { code: '61118', title: 'New Zealander' },
    { code: '61199', title: 'Other Ethnicity nec' },
    { code: '94444', title: "Don't Know" },
    { code: '95555', title: 'Refused to Answer' },
    { code: '96666', title: 'Repeated Value' },
    { code: '97777', title: 'Response Unidentifiable' },
    { code: '98888', title: 'Response Outside Scope' },
    { code: '99999', title: 'Not Stated' }
];

export const MOEIwiCodes = [
    { code: '0101', title: 'Te Aupōuri' },
    { code: '0102', title: 'Ngāti Kahu' },
    { code: '0103', title: 'Ngāti Kurī' },
    { code: '0104', title: 'Ngāpuhi' },
    { code: '0105', title: 'Ngāpuhi ki Whaingaroa-Ngāti Kahu ki Whaingaroa' },
    { code: '0106', title: 'Te Rarawa' },
    { code: '0107', title: 'Ngāi Takoto' },
    { code: '0108', title: 'Ngāti Wai' },
    { code: '0109', title: 'Ngāti Whātua (not Ōrākei or Kaipara)' },
    { code: '0110', title: 'Te Kawerau ā Maki' },
    { code: '0111', title: 'Te Uri-o-Hau' },
    { code: '0112', title: 'Te Roroa' },
    { code: '0113', title: 'Ngāti Whātua o Kaipara' },
    { code: '0114', title: 'Ngāti Whātua o Ōrākei' },
    { code: '0115', title: 'Ngāi Tai ki Tāmaki  ' },
    { code: '0116', title: 'Ngāti Hine (Te Tai Tokerau)' },
    { code: '0117', title: 'Te Paatu' },
    { code: '0118', title: 'Ngāti Manuhiri' },
    { code: '0119', title: 'Ngāti Rēhua' },
    { code: '0201', title: 'Ngāti Hako' },
    { code: '0202', title: 'Ngāti Hei' },
    { code: '0203', title: 'Ngāti Maru (Hauraki)' },
    { code: '0204', title: 'Ngāti Paoa' },
    { code: '0205', title: 'Patukirikiri' },
    { code: '0206', title: 'Ngāti Porou ki Harataunga ki Mataora' },
    { code: '0207', title: 'Ngāti Pūkenga ki Waiau' },
    { code: '0208', title: 'Ngāti Rāhiri Tumutumu' },
    { code: '0210', title: 'Ngāti Tamaterā' },
    { code: '0211', title: 'Ngāti Tara Tokanui' },
    { code: '0212', title: 'Ngāti Whanaunga' },
    { code: '0301', title: 'Ngāti Haua (Waikato)' },
    { code: '0302', title: 'Ngāti Maniapoto' },
    { code: '0303', title: 'Raukawa (Waikato)' },
    { code: '0304', title: 'Waikato' },
    { code: '0305', title: 'Ngāti Te Ata' },
    { code: '0306', title: 'Ngāti Hīkairo ' },
    { code: '0307', title: 'Rereahu' },
    { code: '0308', title: 'Ngāti Tipa' },
    { code: '0309', title: 'Ngāti Korokī Kahukura' },
    { code: '0310', title: 'Ngāti Tamaoho' },
    { code: '0311', title: 'Te Ākitai-Waiohua' },
    { code: '0401', title: 'Ngāti Pikiao (Te Arawa)' },
    { code: '0402', title: 'Ngāti Rangiteaorere (Te Arawa)' },
    { code: '0403', title: 'Ngāti Rangitihi (Te Arawa)' },
    { code: '0404', title: 'Ngāti Rangiwewehi (Te Arawa)' },
    { code: '0405', title: 'Tapuika (Te Arawa)' },
    { code: '0406', title: 'Ngāti Tarāwhai (Te Arawa)' },
    { code: '0407', title: 'Tūhourangi (Te Arawa)' },
    { code: '0408', title: 'Uenuku-Kōpako (Te Arawa)' },
    { code: '0409', title: 'Waitaha (Te Arawa)' },
    { code: '0410', title: 'Ngāti Whakaue (Te Arawa)' },
    { code: '0411', title: 'Ngāti Tūwharetoa (ki Taupō)' },
    { code: '0412', title: 'Ngāti Tahu-Ngāti Whaoa (Te Arawa)' },
    { code: '0413', title: 'Ngāti Mākino' },
    { code: '0414', title: 'Ngāti Kearoa / Ngāti Tuarā' },
    { code: '0415', title: 'Ngāti Rongomai (Te Arawa)' },
    { code: '0501', title: 'Ngāti Pūkenga' },
    { code: '0502', title: 'Ngāi Te Rangi ' },
    { code: '0503', title: 'Ngāti Ranginui' },
    { code: '0504', title: 'Ngāti Awa' },
    { code: '0505', title: 'Ngāti Manawa' },
    { code: '0506', title: 'Ngāi Tai (Tauranga Moana/Mātaatua)' },
    { code: '0507', title: 'Tūhoe' },
    { code: '0508', title: 'Whakatōhea' },
    { code: '0509', title: 'Te Whānau-ā-Apanui' },
    { code: '0510', title: 'Ngāti Whare' },
    { code: '0511', title: 'Ngā Pōtiki ā Tamapahore' },
    { code: '0512', title: 'Te Upokorehe' },
    { code: '0513', title: 'Ngāti Tūwharetoa ki Kawerau ' },
    { code: '0601', title: 'Ngāti Porou' },
    { code: '0602', title: 'Te Aitanga-a-Māhaki' },
    { code: '0603', title: 'Rongowhakaata' },
    { code: '0604', title: 'Ngāi Tāmanuhiri' },
    { code: '0605', title: 'Te Aitanga ā Hauiti' },
    { code: '0701', title: 'Rongomaiwahine (Te Māhia)' },
    { code: '0702', title: 'Ngāti Kahungunu ki Te Wairoa' },
    { code: '0703', title: 'Ngāti Kahungunu ki Heretaunga' },
    { code: '0704', title: 'Ngāti Kahungunu ki Wairarapa' },
    { code: '0706', title: "Rangitāne (Te Matau-a-Māui/Hawke's Bay/Wairarapa)" },
    { code: '0707', title: 'Ngāti Kahungunu ki Te Whanganui-a-Orotu' },
    { code: '0708', title: 'Ngāti Kahungunu ki Tamatea' },
    { code: '0709', title: 'Ngāti Kahungunu ki Tamakinui a Rua' },
    { code: '0710', title: 'Ngāti Pāhauwera' },
    { code: '0711', title: 'Ngāti Rākaipaaka' },
    { code: '0712', title: 'Ngāti Hineuru' },
    { code: '0713', title: 'Maungaharuru Tangitū' },
    { code: '0714', title: 'Rangitāne o Tamaki nui ā Rua' },
    { code: '0715', title: 'Ngāti Ruapani ki Waikaremoana' },
    { code: '0716', title: 'Te Hika o Pāpāuma' },
    { code: '0801', title: 'Te Atiawa (Taranaki)' },
    { code: '0802', title: 'Ngāti Maru (Taranaki)' },
    { code: '0803', title: 'Ngāti Mutunga (Taranaki)' },
    { code: '0804', title: 'Ngā Rauru' },
    { code: '0805', title: 'Ngā Ruahine' },
    { code: '0806', title: 'Ngāti Ruanui' },
    { code: '0807', title: 'Ngāti Tama (Taranaki)' },
    { code: '0808', title: 'Taranaki' },
    { code: '0809', title: 'Tangāhoe' },
    { code: '0810', title: 'Pakakohi' },
    { code: '0901', title: 'Ngāti Apa (Rangitīkei)' },
    { code: '0902', title: 'Te Ati Haunui-a-Pāpārangi' },
    { code: '0903', title: 'Ngāti Haua (Taumarunui)' },
    { code: '0904', title: 'Ngāti Hauiti (Rangitīkei)' },
    { code: '0905', title: 'Ngāti Whitikaupeka (Rangitīkei)' },
    { code: '0906', title: 'Ngāi Te Ohuake (Rangitīkei)' },
    { code: '0907', title: 'Ngāti Tamakōpiri (Rangitīkei)' },
    { code: '0908', title: 'Ngāti Rangi (Ruapehu, Whanganui)' },
    { code: '0909', title: 'Uenuku (Ruapehu, Waimarino)' },
    { code: '0910', title: 'Tamahaki (Ruapehu, Waimarino)' },
    { code: '0911', title: 'Tamakana (Ruapehu, Waimarino)' },
    { code: '1001', title: 'Te Atiawa (Te Whanganui-a-Tara/Wellington)' },
    { code: '1002', title: 'Muaūpoko' },
    { code: '1003', title: 'Rangitāne (Manawatū)' },
    { code: '1004', title: 'Ngāti Raukawa (Horowhenua/Manawatū)' },
    { code: '1005', title: 'Ngāti Toarangatira (Te Whanganui-a-Tara/Wellington)' },
    { code: '1006', title: 'Te Atiawa ki Whakarongotai' },
    { code: '1007', title: 'Ngāti Tama ki Te Upoko o Te Ika (Te Whanganui-a-Tara/Wellington)' },
    { code: '1008', title: 'Ngāti Kauwhata' },
    { code: '1009', title: 'Ngāti Tukorehe ' },
    { code: '1101', title: 'Te Atiawa (Te Waipounamu/South Island)' },
    { code: '1102', title: 'Ngāti Koata' },
    { code: '1103', title: 'Ngāti Kuia' },
    { code: '1104', title: 'Kāti Māmoe' },
    { code: '1105', title: 'Moriori' },
    { code: '1106', title: 'Ngāti Mutunga (Wharekauri/Chatham Islands)' },
    { code: '1107', title: 'Rangitāne (Te Waipounamu/South Island)' },
    { code: '1108', title: 'Ngāti Rārua' },
    { code: '1109', title: 'Ngāi Tahu / Kāi Tahu' },
    { code: '1110', title: 'Ngāti Tama (Te Waipounamu/South Island)' },
    { code: '1111', title: 'Ngāti Toarangatira (Te Waipounamu/South Island)' },
    { code: '1112', title: 'Waitaha (Te Waipounamu/South Island)' },
    { code: '1113', title: 'Ngāti Apa ki Te Rā Tō' },
    { code: '2001', title: 'Tainui, iwi not named' },
    { code: '2002', title: 'Te Arawa, iwi not named' },
    { code: '2003', title: 'Tākitimu, iwi not named' },
    { code: '2004', title: 'Aotea, iwi not named' },
    { code: '2005', title: 'Mātaatua, iwi not named' },
    { code: '2006', title: 'Mahuru, iwi not named' },
    { code: '2007', title: 'Māmari, iwi not named' },
    { code: '2008', title: 'Ngātokimatawhaorua, iwi not named' },
    { code: '2009', title: 'Nukutere, iwi not named' },
    { code: '2010', title: 'Tokomaru, iwi not named' },
    { code: '2011', title: 'Kurahaupō, iwi not named' },
    { code: '2012', title: 'Muriwhenua, iwi not named' },
    { code: '2013', title: 'Hauraki / Pare Hauraki, iwi not named' },
    { code: '2014', title: 'Tūranganui a Kiwa, iwi not named' },
    { code: '2015', title: 'Te Tauihu o Te Waka a Māui, iwi not named' },
    { code: '2016', title: 'Tauranga Moana, iwi not named' },
    { code: '2017', title: 'Horouta, iwi not named' },
    { code: '2018', title: 'Mōkai Pātea, iwi not named ' },
    { code: '2101', title: 'Te Atiawa, region not known' },
    { code: '2102', title: 'Ngāti Haua, region not known' },
    { code: '2103', title: 'Ngāti Maru, region not known' },
    { code: '2104', title: 'Ngāti Mutunga, region not known' },
    { code: '2105', title: 'Rangitāne, region not known' },
    { code: '2106', title: 'Ngāti Raukawa, region not known' },
    { code: '2107', title: 'Ngāti Tama, region not known' },
    { code: '2108', title: 'Ngāti Toa, region not known' },
    { code: '2109', title: 'Waitaha, region not known' },
    { code: '2110', title: 'Ngāti Apa, region not known' },
    { code: '2111', title: 'Ngāi Tai, region not known ' },
    { code: '2112', title: 'Ngāti Kahungunu, region not known ' },
    { code: '2113', title: 'Ngāti Tūwharetoa, region not known' },
    { code: '2201', title: 'Hapū Affiliated to More Than One Iwi' },
    { code: '2301', title: 'Te Tai Tokerau/Tāmaki-makaurau Region, Iwi not named' },
    { code: '2302', title: 'Hauraki Region, Iwi not named' },
    { code: '2303', title: 'Waikato/Te Rohe Pōtae Region, Iwi not named' },
    { code: '2304', title: 'Te Arawa/Taupō Region, Iwi not named' },
    { code: '2305', title: 'Tauranga Moana/Mātaatua Region, Iwi not named' },
    { code: '2306', title: 'Te Tai Rāwhiti Region, Iwi not named' },
    { code: '2307', title: 'Te Matau-a-Māui/Wairarapa Region, Iwi not named' },
    { code: '2308', title: 'Taranaki Region, Iwi not named' },
    { code: '2309', title: 'Whanganui/Rangitīkei Region, Iwi not named' },
    { code: '2310', title: 'Manawatū/Horowhenua/Te Whanganui-a-Tara Region, Iwi not named' },
    { code: '2311', title: 'Te Waipounamu/Wharekauri Region, Iwi not named' },
    { code: '9999', title: 'Not Stated' }
];
