import { getJSON } from './http';
import { RevokeEndpointOptions, TokenEndpointOptions, TokenEndpointResponse } from './types';
import { createQueryParams } from './utils';

export async function oauthToken({ baseUrl, timeout, ...options }: TokenEndpointOptions) {
    const body = createQueryParams(options);

    return await getJSON<TokenEndpointResponse>(`${baseUrl}/oauth/token`, timeout, {
        method: 'POST',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export async function oauthRevoke({ baseUrl, timeout, ...options }: RevokeEndpointOptions) {
    const body = createQueryParams(options);

    return await getJSON<Record<string, never>>(`${baseUrl}/oauth/token`, timeout, {
        method: 'POST',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}
